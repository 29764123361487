import { FILES_PERMISSION_ID } from '@/providers/accessControl/endpoints/permissions';
import { capitalize } from 'lodash';
import React from 'react';
import { Checkbox, List, Text } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';
import { DataType } from 'vkit/lib/shared/components/list/types/list.type';
import { ListCategories } from '../../utils';

interface BlockPermissionChildrenListProps {
  data: ListCategories[],
  parentId: string
  handleItemPermission:
    (parentId: string, id: string, type: FILES_PERMISSION_ID) => void
  shouldDisable: (id: string, type: FILES_PERMISSION_ID) => boolean
  isLoading: boolean
}

const BlockPermissionChildrenList: React.FC<BlockPermissionChildrenListProps> = ({
  data,
  parentId,
  handleItemPermission,
  shouldDisable,
  isLoading
}) => {

  const columns: DataType[] = [
    {
      title: '',
      value: ( { name }: ListCategories  ) => {
        return (
          <Row style={{ padding: 0 }}>
            <Text size='medium' value={ capitalize(name) } />
          </Row>
        );
      }
    },
    {
      align: 'center',
      title: 'Adicionar',
      value: ( { id, hasAddPermission }: ListCategories  ) => {
        const isDisabled = shouldDisable(id, FILES_PERMISSION_ID.CREATE) || isLoading;
        return (
          <Checkbox
            small
            checked={ hasAddPermission }
            onChange={ () => handleItemPermission(parentId, id, FILES_PERMISSION_ID.CREATE) }
            disabled={ isDisabled }
            title={ isDisabled ? 'Você não pode adicionar ou alterar esta permissão.' : '' }
          />
        );
      }
    },
    {
      align: 'center',
      title: 'Visualizar',
      value: ( { id, hasViewPermission }: ListCategories  ) => {
        const isDisabled = shouldDisable(id, FILES_PERMISSION_ID.READ) || isLoading;
        return (
          <Checkbox
            small
            checked={ hasViewPermission }
            onChange={ () => handleItemPermission(parentId, id, FILES_PERMISSION_ID.READ) }
            disabled={ isDisabled }
            title={ isDisabled ? 'Você não pode adicionar ou alterar esta permissão.' : '' }
          />
        );
      }
    },
    {
      align: 'center',
      title: 'Excluir',
      value: ( { id, hasDeletePermission }: ListCategories  ) => {
        const isDisabled = shouldDisable(id, FILES_PERMISSION_ID.DELETE) || isLoading;
        return (
          <Checkbox
            small
            checked={ hasDeletePermission }
            onChange={ () => handleItemPermission(parentId, id, FILES_PERMISSION_ID.DELETE) }
            disabled={ isDisabled }
            title={ isDisabled ? 'Você não pode adicionar ou alterar esta permissão.' : '' }
          />
        );
      }
    },
  ]

  return (
    <List
      data={ data }
      dataList={ columns }
    />
  )
};

export default BlockPermissionChildrenList;
