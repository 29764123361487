import TextFieldDebounce from '@/components/textFieldDebounce/textFieldDebounce';
import SelectStatus from '@/pages/users/list/components/selectStatus/selectStatus';
import { USER_LIST_FILTERS, USER_STATUS } from '@/pages/users/list/entities/user.enums';
import { ParamsToFilterUser } from '@/pages/users/list/entities/user.interfaces';
import React from 'react';

interface UseUserFiltersProps {
  onChange: (params: ParamsToFilterUser) => void;
  filters: ParamsToFilterUser;
}

interface UseUserFiltersReturn {
  fieldsToChoose: Record<USER_LIST_FILTERS, any>
}

const useUserFilterFields = (
  {
    onChange,
    filters,
  }: UseUserFiltersProps
): UseUserFiltersReturn => {
  const onChangeField = (key: string, value?: string | string[] | USER_STATUS[]): void => {
    onChange({ ...filters, [key]: value });
  }

  const fieldsToChoose = {
    [USER_LIST_FILTERS.SEARCH]: {
      component: (
        <TextFieldDebounce
          clearable
          small
          iconDir='right'
          icon='search-outline'
          label='Nome'
          value={ filters.q }
          onChange={ (value: string) => onChangeField('q', value) }
        />
      ),
    },
    [USER_LIST_FILTERS.EMAIL]: {
      component: (
        <TextFieldDebounce
          clearable
          small
          iconDir='right'
          icon='search-outline'
          label='E-mail'
          value={ filters.email }
          onChange={ (value: string) => onChangeField('email', value) }
        />
      ),
    },
    [USER_LIST_FILTERS.STATUS]: {
      component: (
        <SelectStatus
          onChange={ (value?: USER_STATUS[]) => onChangeField('status', value) }
          value={ filters.status || [] }
        />
      ),
    }
  }

  return {
    fieldsToChoose,
  }
};

export default useUserFilterFields;
