import { useHistoryParams } from '@/navigation';
import { userDetailsProvider } from '@/providers/accessControl/endpoints/user';
import { User, UserPermission, UserPermissionPayload } from '@/providers/accessControl/endpoints/users';
import React, { useCallback, useEffect, useState } from 'react';
import { Icon, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import { BlockPermission, BlockPermissionChildrenList } from './components';
import useFileCategoryPermissions from './hooks/useFileCategoryPermissions';

interface FileCategoryPermissionsProps {
  initialPermissions: UserPermission[]
  updateFilesDirPermissions: (userPermissions: UserPermissionPayload[]) => void
  isLoading: boolean
  disabled?: boolean
}

const FileCategoryPermissions: React.FC<FileCategoryPermissionsProps> = ({
  updateFilesDirPermissions,
  initialPermissions,
  isLoading,
  disabled,
}) => {
  const { userId } = useHistoryParams()
  const [ currentUser, setCurrentUser ] = useState<User>()

  const {
    data,
    listUserPermissions,
    handleBlockPermission,
    handleItemPermission,
    shouldDisableCurrentItem,
    shouldDisableParent
  } = useFileCategoryPermissions({
    userId,
    currentUser,
    initialPermissions,
    disabled,
  });

  const loadCurrentUser = useCallback(async () => {
    try {
      const currentUser = await userDetailsProvider.index({}) as unknown as User;
      setCurrentUser(currentUser);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleExpanded = (id: string, index: number): boolean => {
    const parentBlock = data.find((item) => item.id === id);

    if (index === 0) {
      return true;
    }

    if (parentBlock) {
      return parentBlock.children.some(
        (child) => child.hasAddPermission || child.hasDeletePermission || child.hasViewPermission);
    }

    return false;
  }

  useEffect(() => {
    loadCurrentUser();
  }, [loadCurrentUser]);

  useEffect(() => {
    updateFilesDirPermissions(listUserPermissions);
  }, [listUserPermissions, updateFilesDirPermissions])

  return data.length ? (
    <>
      <Row style={{ padding: '0 0 12px' }}>
        <Grid gap={8}>
          <Icon name={'file-outline'} color='default' />
          <Text
            color='default'
            size='small'
            title
            value={'Permissões de pastas'}
          />
        </Grid>
      </Row>

      { data.map((item, index) => (
          <BlockPermission
            isLoading={ isLoading }
            key={item.id}
            shouldDisabled={ shouldDisableParent }
            category={ item }
            expanded={ handleExpanded(item.id, index) }
            handleBlockPermission={ handleBlockPermission }
          >
            <BlockPermissionChildrenList
              isLoading={ isLoading }
              data={ item.children }
              parentId={ item.id }
              handleItemPermission={ handleItemPermission }
              shouldDisable={ shouldDisableCurrentItem }
            />
          </BlockPermission>
        ))
      }

    </>
  ): null
}

export default FileCategoryPermissions;
