import { DialogConfirm } from '@/components';
import useDialogConfirm from '@/components/dialogConfirm/hooks/useDialogConfirm';
import { useHistoryNavigator, useHistoryParams } from '@/navigation';
import ButtonInactive from '@/pages/users/createOrUpdate/components/ButtonInactive/ButtonInactive';
import ButtonReactive from '@/pages/users/createOrUpdate/components/ButtonReactive/ButtonReactive';
import ButtonReset2FA from '@/pages/users/createOrUpdate/components/ButtonReset2FA/ButtonReset2FA';
import { User } from '@/providers/accessControl/endpoints/user';
import React from 'react';
import { Grid, Row } from 'vkit/lib/context';
import useUsers from '../../../list/hooks/useUsers';

interface ActionsProps {
  user: User
}

const Actions: React.FC<ActionsProps> = ({ user }) => {
  const params = useHistoryParams();
  const navigate = useHistoryNavigator();

  const {
    deleteUser,
    onReset2FA,
    reactivateUser,
    loading,
  } = useUsers({
    reactivateUserCallback: () => {
      navigate.replace(`/${params.scopeType}/${params.scopeId}/users/${params.userId}/details`)
    }
  })

  const {
    useTitle: useDialogConfirmTitle,
    useText: useDialogConfirmText,
    useOpen: useDialogConfirmOpen,
    useFnCallback,
    useConfirm: confirm,
  } = useDialogConfirm()

  return (
    <Row style={{ flexDirection: 'row', padding: 0 }}>
      <DialogConfirm
        fnCallback={useFnCallback}
        open={useDialogConfirmOpen}
        text={useDialogConfirmText}
        title={useDialogConfirmTitle}
      />
      
      <Grid gap={16}>
        <ButtonReset2FA
          user={ user }
          reset2FA={ onReset2FA }
          confirm={ confirm }
          loading={ loading.totp }
        />

        <ButtonInactive
          user={ user }
          inactiveUser={ deleteUser }
          confirm={ confirm }
          loading={ loading.totp }
        />

        <ButtonReactive
          user={ user }
          reactivateUser={ reactivateUser }
          confirm={ confirm }
          loading={ loading.totp }
        />
      </Grid>
    </Row>
  )
}

export default Actions
