import React from 'react'

import Icon from 'components/icon'

import { LayerType } from '../types/accordion.type'

import classesBuilder from 'shared/utils/classesBuilder'
import UseLayer from './hooks/useLayer'

import style from 'theme/components/accordion/accordion.module.scss'

const Layer: React.FC<LayerType> = ({
  body,
  full,
  title,
  height,
  expanded,
  handleChange,
  accordionItem,
  disabled,
  children,
  cellSpacing,
  handleExpanded,
}) => {
  const { getAccordionContent, useAccordion, useAccordionHeight, setAccordion } = UseLayer(
    !!expanded,
  )

  const handleAccordionChange = (): void => {
    setAccordion(!useAccordion)
    handleChange?.(accordionItem)
    handleExpanded?.(!useAccordion)
  }

  const classNames = {
    layer: true,
    full,
    open: useAccordion,
    disabled,
    cellSpacing,
  }

  const accordionElement = body || children

  return (
    <div className={classesBuilder(style, classNames)}>
      <div className={style.title} {...(!disabled && { onClick: () => handleAccordionChange() })}>
        <div className={style.label}>{title}</div>
        <span className={style.icon}>
          <Icon name="arrow-ios-downward-outline" />
        </span>
      </div>

      <div
        style={{ maxHeight: `${useAccordionHeight}px` }}
        className={style.body}
        ref={getAccordionContent}
      >
        <div style={{ maxHeight: `${height}px` }} className={style.content}>
          {accordionElement}
        </div>
      </div>
    </div>
  )
}

export default Layer
