enum ValidateEnumType {
  cep = 'cep',
  cnpj = 'cnpj',
  cpf = 'cpf',
  date = 'date',
  number = 'number',
  required = 'required',
}

export default ValidateEnumType
