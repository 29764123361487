import { ToastStatus } from '@/components'
import { useHistoryNavigator, useHistoryParams } from '@/navigation'
import { ParamsToFilterUser } from '@/pages/users/list/entities/user.interfaces';
import { totpResetErrors, totpResetProvider } from '@/providers/accessControl/endpoints/totp-reset'
import { deletedUsersProvider, User, usersProvider } from '@/providers/accessControl/endpoints/users';
import { EVENTS } from '@/shareds/constants/events'
import { ObjectType } from '@/shareds/types'
import removeInvalids from '@/utils/removeInvalids'
import { toastError } from '@/utils/toastError'
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

interface IUseUsers {
  onReset2FA: (userId: string) => Promise<void>
  deleteUser: (userId: string) => Promise<void>
  reactivateUser: (userId: string) => Promise<void>
  loadUsers: (params: ObjectType) => Promise<void>
  pagination: ObjectType
  setListBlur: Function
  setFilters: Dispatch<SetStateAction<Partial<ParamsToFilterUser>>>;
  useData: User[] | null
  useFilters: ObjectType
  useListBlur: boolean
  useListRef: MutableRefObject<HTMLDivElement | null>
  loading: ObjectType
}

interface UseUsersProps {
  reactivateUserCallback: () => void
}

const useUsers = (
  props?: UseUsersProps
): IUseUsers => {
  const {
    reactivateUserCallback,
  } = props || {};

  const [loading, setLoading] = useState<ObjectType>({})
  const [useListBlur, setListBlur] = useState(false)
  const [useFilters, setFilters] = useState<ObjectType>({})
  const [useData, setData] = useState<User[] | null>([])
  const useListRef = useRef(null)
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  })
  const useParams = useHistoryParams()
  const { toast } = ToastStatus()
  const navigate = useHistoryNavigator()
  
  const updatePagination = useCallback((update: ObjectType) => setPagination((current) => ({
    ...current,
    ...update,
  })), [])

  const loadUsers = useCallback(
    async (updateFilters = {}): Promise<void> => {
      setFilters(updateFilters)

      if (!useParams.scopeId || !useParams.scopeType) {
        return
      }
      
      setLoading({ users: true })
      setData([])
      try {
        updatePagination({
          page: updateFilters.page,
          pageSize: updateFilters.pageSize,
        })
        
        const filter =  removeInvalids<User>({
          type: useParams.scopeType,
          scopeId: useParams.scopeId,
          include: 'userPermissions',
          withDeleted: true,
          ...updateFilters,
        })

        const { data: users, total } = (await usersProvider.index(filter)) || {}
        setData(users?.length ? users : null)

        updatePagination({ total })
      } catch (error) {
        setData(null)
        toastError({
          error,
          messageDefault: 'Falha ao carregar lista usuários',
        })
      } finally {
        setLoading({ users: false })
      }
    },
    [updatePagination, useParams.scopeId, useParams.scopeType],
  )
  
  const reactivateUser = async (userId: string): Promise<void> => {
    try {
      setLoading({ reactivateUser: true })
      await deletedUsersProvider.remove(userId)
      reactivateUserCallback?.();
      toast('Feito', 'O usuário foi reativado com sucesso.', 'success')
    } catch (error) {
      toastError({
        error,
        messageDefault: 'Falha ao reativar o usuário',
      })
    } finally {
      setLoading({ reactivateUser: false })
    }
  }

  const deleteUser = async (userId: string): Promise<void> => {
    try {
      setLoading({ deleteUser: true })
      await usersProvider.remove(userId)
      await loadUsers()
      navigate.goBack()
      toast('Feito', 'O usuário foi inativado com sucesso.', 'success')
    } catch (error) {
      toastError({
        error,
        messageDefault: 'Falha ao inativar o usuário',
      })
    } finally {
      setLoading({ deleteUser: false })
    }
  }

  const onReset2FA = async(userId: string): Promise<void> => {
    try {
      setLoading({ totp: true })
      await totpResetProvider.create({ userId })
      toast('Feito', 'Uma solicitação de redefinição de autenticação em dois fatores foi enviado para o email do usuário.', 'success')
      const toastEvent = new CustomEvent(EVENTS.REFRESH_USERS)
      window.dispatchEvent(toastEvent)
    } catch (error) {
      toastError({
        error,
        messageDefault: 'Falha ao redefinir autenticação em dois fatores.',
        messageErrorByMessageErrorRequest: totpResetErrors,
      })
    } finally {
      setLoading({ totp: false })
    }
  }
  
  useEffect(() => {
    (async () => loadUsers())()
  }, [loadUsers])

  return {
    onReset2FA,
    reactivateUser,
    deleteUser,
    loadUsers,
    setListBlur,
    setFilters,
    useData,
    useFilters,
    useListBlur,
    useListRef,
    loading,
    pagination,
  }
}

export default useUsers
