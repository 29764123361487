import { USER_STATUS } from '@/pages/users/list/entities/user.enums';
import restfulBase from '@/providers/accessControl/base'
import { ObjectType } from '@/shareds/types'
import { Permission } from '../permissions'

export enum Scopes {
  BROKER = 'broker',
  COMPANY = 'company',
}

export interface UserPermission extends Omit<Permission, 'name' | 'type' | 'description'> {
  createdBy: string | null,
  deletedBy: string | null,
  updatedBy: string | null,
  userId: string,
  permissionId: string
  scope: null
}

export interface User {
  id?: string
  name: string
  email: string
  type: Scopes
  status?: USER_STATUS
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  scopeIds?: string[]
  permissions: string[]
  userPermissions: UserPermission[]
}

export interface UserParams extends User {
  include?: string | string[]
  withDeleted?: boolean
}

export interface UserPermissionPayload {
  permissionId: string // Recebe o permissionID
  scope?: string // Recebe o ID da pasta.
}
export interface UserPayload extends Omit<User, 'userPermissions' | 'permissions'> {
  userPermissions: UserPermissionPayload[]
}

export const userInitialState: User = {
  name: '',
  email: '',
  type: Scopes.COMPANY,
  scopeIds: [],
  permissions: [],
  userPermissions: []
}

export const userMessageErrorByMessageErrorRequest: ObjectType = {
  'E-mail address already registered for another user': 'Endereço de e-mail já registrado por outro usuário'
}

export const usersProvider = restfulBase<UserParams, UserPayload>('/users')
export const deletedUsersProvider = restfulBase<UserParams, UserPayload>('/deleted-users')
