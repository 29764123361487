import { useState, useEffect, ChangeEvent } from 'react'
import * as validation from '../validates'

import { useValidateType, validateNamesType } from '../types/formValidate.type'

type ObjectType = { [key: string]: any }
type TouchedType = { [key: string]: boolean }
type useFormValidateType = {
  fields: { [key: string]: (validateNamesType | Function)[] }
  fnCallback: Function
  onChangeForm?: Function
  onError?: Function
  onBeforeValidateForm?: Function
  objectName?: string
  resource?: ObjectType
  resourceDefault?: ObjectType
  clearNextFields?: boolean
}

export interface IUseFormValidates {
  useValues: ObjectType
  useValidate: useValidateType
  useErrors: ObjectType
  onChange: (...item: any) => void
  onBlur: (...item: any) => void
  onValidateForm: (...item: any) => void
  updateFieldErrors: (...item: any) => void
}

const useFormValidates = ({
  fields,
  fnCallback,
  onChangeForm,
  onError,
  onBeforeValidateForm,
  objectName,
  resource,
  resourceDefault,
  clearNextFields,
}: useFormValidateType): IUseFormValidates => {
  const [useErrors, setErrors] = useState<ObjectType>({})
  const [useToucheds, setToucheds] = useState<TouchedType>({})
  const [useValidate, setValidate] = useState<useValidateType>({})

  const getValues = (resourceData: ObjectType): ObjectType =>
    ((objectName ? resourceData?.[objectName] : resourceData) || {}) as ObjectType

  const fnValidates = async (name: string, value: string): Promise<string | null> => {
    if (!useValidate[name]) {
      return null
    }

    for (const fnValidate of useValidate[name]) {
      const error = await fnValidate(value)
      if (error) {
        return error
      }
    }

    return null
  }

  const onChange = async (name: string, value: string): Promise<void> => {
    setToucheds({
      ...useToucheds,
      [name]: true,
    })

    let params: {}

    if (objectName) {
      const param = resource?.[objectName] || {}

      params = {
        ...resource,
        [objectName]: {
          ...param,
          [name]: value,
        },
      }
    } else {
      params = {
        ...resource,
        [name]: value,
      }
    }

    if (clearNextFields) {
      let canClean = false

      for (const [key, valueParam] of Object.entries(params)) {
        params = {
          ...params,
          [key]: canClean ? '' : valueParam,
        }

        if (key === name) {
          canClean = true
        }
      }
    }

    onChangeForm?.(params)
  }

  const onBlur = async (name: string, value: string): Promise<void> => {
    await updateFieldErrors(name, value)
  }

  const updateFieldErrors = async (name: string, value: string): Promise<void> => {
    const { [name]: removedError, ...rest } = useErrors

    const error = await fnValidates(name, value)

    setErrors({
      ...rest,
      ...(error && useToucheds[name] && { [name]: error }),
    })
  }

  const validateForm = async (values: any): Promise<{ errors: any; touched: any }> => {
    const formValidation = await Object.keys(fields).reduce(
      async (
        accPromises,
        key,
      ): Promise<{
        errors: ObjectType
        touched: TouchedType
      }> => {
        const acc = await accPromises
        const value = (values[key] as string) ?? ''
        const newError = await fnValidates(key, value)
        const newTouched = { [key]: true }

        return {
          errors: {
            ...acc.errors,
            [key]: newError,
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          },
        }
      },
      Promise.resolve({
        errors: { ...useErrors },
        touched: { ...useToucheds },
      }),
    )

    return formValidation
  }

  const onValidateForm = async (evt: ChangeEvent<HTMLFormElement> | null): Promise<boolean> => {
    evt?.preventDefault()

    const valuesUpdated = getValues(resource || {})
    const valuesDefault = getValues(resourceDefault || {})
    const values = {
      ...valuesDefault,
      ...valuesUpdated,
    }

    const { errors, touched } = await validateForm(values)

    setErrors(errors)
    setToucheds(touched)

    onBeforeValidateForm?.(values)

    if (
      Object.values(errors).every((t) => !t) &&
      Object.values(touched).length === Object.values(fields).length
    ) {
      fnCallback(values)
      setErrors({})
      setToucheds({})
    } else {
      onError?.(errors)
    }

    return Object.values(errors).every((t) => !t)
  }

  useEffect(() => {
    const validations = {}

    for (const [fieldName, validateKeys] of Object.entries(fields)) {
      Object.assign(validations, {
        [fieldName]: validateKeys.map((validateKey) => {
          if (typeof validateKey === 'function') {
            return validateKey
          }

          return validation[validateKey]
        }),
      })
    }

    setValidate(validations)
  }, [fields])

  return {
    onBlur,
    onChange,
    useErrors,
    useValidate,
    useValues: (objectName ? resource?.[objectName] ?? {} : resource ?? {}) as ObjectType,
    onValidateForm,
    updateFieldErrors,
  }
}

export default useFormValidates
