import React, {  useRef } from 'react'
import { Row } from 'vkit/lib/context'
import { ButtonActions } from '@/components'

type SelectPageSizeType = {
  onChangePageSize: Function
  pageSize: number
}

const SelectPageSize: React.FC<SelectPageSizeType> = ({ onChangePageSize, pageSize }) => {
  const useOptionsRef = useRef([10, 25, 50])
  
  return (
    <Row
      style={{
        padding: 0,
      }}
    >
      <ButtonActions
        invertColor
        color='default'
        label={`Exibindo ${pageSize} registros por página`}
        actionOptions={useOptionsRef.current.map((num) => ({
          label: `Exibir ${num} registros por página`,
          onClick: () => onChangePageSize(num),
          icon: 'hash'
        }))}
      />
    </Row>
  )
}

export default SelectPageSize
