import React from 'react'

import Icon from 'shared/components/icon'
import Text from 'shared/components/text'
import { classesBuilder, dataResolver } from 'shared/utils'

import { DEFAULT_EMPTY_MSG } from './constants'

import UseItemsVirtualized from './hooks/virtualScroll/useItemsVirtualized'

import { getClassNamesBody, getClassNamesHeader, getItemsSerialize } from './utils'

import { DataType, ListType } from './types/list.type'

import style from 'theme/components/list/list.module.scss'

const List: React.FC<ListType> = ({
  data,
  dataList,
  dataResolve,
  hideHeader,
  itemPushed,
  onClick,
  onHover,
  onPage,
  linesLoader,
  textToEmpty = DEFAULT_EMPTY_MSG,
}) => {
  const items = (
    dataResolve && data ? dataResolver({ data, target: dataResolve }) : data
  ) as Array<any> | null

  const { useHasItemsLoader, useItemsVirtualized, useItemSortable, setSortable } =
    UseItemsVirtualized({
      items,
      size: (data || []).length || linesLoader || 3,
      innerHeight,
      onPage,
    })

  const getIsSetSort = (header: DataType): boolean => {
    const isSortable =
      items?.length && header?.sort && !useHasItemsLoader && typeof header.value === 'string'

    return !!isSortable
  }

  return (
    <div className={style.list}>
      {!hideHeader ? (
        <div className={style.head}>
          {dataList.map((header, key) => (
            <a
              key={key}
              className={getClassNamesHeader({
                header,
                isSortable: getIsSetSort(header),
                style,
                useItemSortable,
              })}
              style={{
                textAlign: header.align,
                flex: header.width ? `0 1 ${header.width}` : '',
              }}
              {...(getIsSetSort(header) ? { onClick: () => setSortable(header.value) } : null)}
            >
              {header.title}
            </a>
          ))}
          {itemPushed ? <div className={style.itemPushed} /> : null}
        </div>
      ) : null}
      {Array.isArray(items) ? (
        <div className={style.body}>
          {useItemsVirtualized.map((item: any, itemKey: number) => (
            <div
              tabIndex={itemKey}
              key={itemKey}
              className={classesBuilder(style, {
                col: true,
                markupHover: onHover && !useHasItemsLoader,
                markupEnter: onClick && !useHasItemsLoader,
                selected: item?.uiSelected === true,
              })}
              {...(onClick ? { onClick: () => onClick?.(item) } : null)}
              {...(typeof onHover === 'function' ? { onMouseEnter: () => onHover(item) } : null)}
            >
              {dataList.map((dataItem, dataItemKey) => (
                <div
                  key={dataItemKey}
                  className={getClassNamesBody({ dataItem, style })}
                  style={{
                    textAlign: dataItem.align,
                    flex: dataItem.width ? `0 1 ${dataItem.width}` : '',
                    fontWeight: dataItem.fontWeight,
                  }}
                >
                  <div
                    className={classesBuilder(style, {
                      itemDetail: true,
                      skeleton: useHasItemsLoader,
                    })}
                  >
                    {!useHasItemsLoader &&
                      getItemsSerialize({
                        dataItemValue: dataItem.value,
                        item,
                      })}
                  </div>
                </div>
              ))}
              {itemPushed ? (
                <div className={style.itemPushed}>
                  <Icon name="arrow-ios-forward-outline" />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ) : (
        <div className={style.msgEmpty}>
          <Text
            size="medium"
            icon="alert-triangle-outline"
            iconColor="warning"
            value={textToEmpty}
          />
        </div>
      )}
    </div>
  )
}

export default List
