import jwtDecode from 'jwt-decode'
import { Scopes } from '@/providers/accessControl/endpoints/users'

interface TokenPayload {
  sub: string
  user: {
    name: string
    type: Scopes
    scopeId: string
    permissions: string[]
  }
}

export const getTokenPayload = (): TokenPayload | null => {
  const token = localStorage.getItem('token') || ''
  if (!token) {
    return null
  }
  return jwtDecode(token)
}
