import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios'
type RequestConfig = (...item: any) => Promise<unknown>

interface RestfulService {
  instance: AxiosInstance
  post: RequestConfig
  remove: RequestConfig
  update: RequestConfig
  get: RequestConfig
}

interface RestfulServiceOptions {
  service: string
  uri: string
  headers?: AxiosRequestHeaders
}

const restfulService = (options: RestfulServiceOptions): RestfulService => {
  const {
    uri,
    service,
    headers
  } = options
  
  const api = axios.create({
    baseURL: uri,
    timeout: 60000,
    headers
  })
  
  api.interceptors.response.use(
    response => response.data
  )

  const post = async (
    data: object,
    resource: string = '',
  ): Promise<unknown> =>
    api.request({
      url: `${service}${resource}`,
      method: 'POST',
      data
    })

  const remove = async (
    id: number,
    resource: string = '',
  ): Promise<unknown> =>
    api.request({
      url: `${service}${resource}/${id}`,
      method: 'DELETE',
    })
  
  const update = async (
    data: object,
    id: number,
    resource: string = '',
  ): Promise<unknown> =>
    api.request({
      url: `${service}${resource}/${id}`,
      data,
      method: 'PATCH'
    })
  
  const get = async (
    params: object,
    resource: string = '',
  ): Promise<unknown> =>
    api.request({
      url: `${service}${resource}`,
      params
    })

  return {
    instance: api,
    post,
    remove,
    update,
    get,
  }
}

export default restfulService
