import { BoxInfo } from '@/components';
import companiesProvider, { Company } from '@/providers/negotiations/endpoints/companies';
import React, { useCallback, useEffect, useState } from 'react';
import { RecursiveList } from './components/recursiveList';

interface CompanyListProps {
  scopeId: string
}

export const CompanyList: React.FC<CompanyListProps> = ({ scopeId }) => {

  const [isLoading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);

  const loadCompaniesTree = useCallback(
    async (): Promise<void> => {
      setLoading(true)
      try {
        const companies = await companiesProvider.index({
          withTree: true,
          id: [scopeId]
        })
        setCompanies(companies.data || []);
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [scopeId],
  )

  useEffect(() => {
    if(scopeId) {
      loadCompaniesTree();
    }
  }, [scopeId, loadCompaniesTree])

  return(
    <BoxInfo
      icon='briefcase'
      title='Empresas'
      loading={isLoading}
    >
      { !isLoading && (
        <>
          <p>
            Este usuário poderá acessar a(s) seguinte(s) empresa(s).
          </p>

          <RecursiveList list={ companies } />
        </>
      )}
    </BoxInfo>
  );
};

export default CompanyList;
