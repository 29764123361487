import restfulBase from '../../base';

export type Company = {
  id: string | string[]
  name: string
  cnpj: string
  companyGroupId: string
  children?: Company[]
}

export const companiesProvider = restfulBase<Company>('companies')

export default companiesProvider
