import { useHistoryParams } from '@/navigation';
import { USER_STATUS } from '@/pages/users/list/entities/user.enums';
import { User } from '@/providers/accessControl/endpoints/user';
import { getTokenPayload } from '@/utils/authentication';
import { checkPermission, PERMISSIONS } from '@/utils/checkPermission';
import React, { useMemo } from 'react';
import { Button } from 'vkit/lib/components';

interface ButtonInactiveProps {
  user: User
  inactiveUser: (userId: string) => Promise<void>
  confirm: (text: string, message: string) => Promise<boolean>
  loading: boolean
}

const ButtonInactive: React.FC<ButtonInactiveProps> = (
  { 
    user, 
    inactiveUser, 
    confirm, 
    loading 
  }
) => {
  const params = useHistoryParams();

  const showButtonInactive = useMemo(() => {
    if (user.status === USER_STATUS.INACTIVATED) {
      return false;
    }

    const { sub } = getTokenPayload() || {};
    if (params.userId === sub) {
      return false;
    }
    
    if (checkPermission(PERMISSIONS.COMPANY_USER_DELETE) && params.scopeType === 'company') {
      return true;
    }
    
    return checkPermission(PERMISSIONS.BROKER_USER_DELETE) && params.scopeType === 'broker';
  }, [params.scopeType, params.userId, user.status])

  const onConfirmDeleteUser = React.useCallback(
    async (): Promise<void> => {
      const response = await confirm('Atenção', 'Quer mesmo inativar este usuário?')
      
      if (response) {
        await inactiveUser(params.userId)
      }
    },
    [inactiveUser, confirm, params],
  )

  return showButtonInactive ? (
    <Button
      outlined
      label='Inativar usuário'
      loading={ loading }
      color='danger'
      onClick={ () => onConfirmDeleteUser() }
    />
  ) : null
}

export default ButtonInactive
