import React from 'react'

import { Select } from 'vkit/lib/components'

enum StatusEnum {
  success = 'success',
  error = 'error',
}

type ObjectType = { [key: string]: string | null }

export interface SelectOption {
  text: string
  value: string | number
  disabled?: boolean
  title?: string
}

interface TextFieldValidateInterface {
  autoRequest?: boolean
  data?: SelectOption[] | null
  disabled?: boolean
  label?: string
  loading?: boolean
  multiple?: boolean
  name: string
  onBlur?: Function
  onChange?: Function
  placeholder?: string
  requestPageParam?: string
  requestParams?: { [key: string]: string }
  requestResponseRootPath?: string
  requestResponseText?: string
  requestResponseValue?: string
  requestRouter?: string
  requestSearchParam?: string
  requestUri?: string
  required?: boolean
  searchable?: boolean
  useErrors: ObjectType
  useValues: ObjectType
}

const SelectValidate: React.FC<TextFieldValidateInterface> = ({
  autoRequest,
  data,
  disabled,
  label,
  loading,
  multiple,
  name,
  onBlur,
  onChange,
  placeholder,
  requestPageParam,
  requestParams,
  requestResponseRootPath,
  requestResponseText,
  requestResponseValue,
  requestRouter,
  requestSearchParam,
  requestUri,
  required,
  searchable,
  useErrors,
  useValues,
}) => (
  <Select
    autoRequest={autoRequest}
    clearable
    data={data}
    disabled={disabled}
    label={label}
    loading={loading}
    multiple={multiple}
    onBlur={(value) => !disabled && onBlur?.(name, value)}
    onChange={(value) => !disabled && onChange?.(name, value)}
    placeholder={placeholder}
    requestPageParam={requestPageParam}
    requestResponseRootPath={requestResponseRootPath}
    requestResponseText={requestResponseText}
    requestResponseValue={requestResponseValue}
    requestRouter={requestRouter}
    requestSearchParam={requestSearchParam}
    requestUri={requestUri}
    requiredSign={required}
    searchable={searchable}
    status={!disabled && useErrors[name] ? StatusEnum.error : undefined}
    textHelper={!disabled ? useErrors[name] ?? '' : undefined}
    value={useValues[name] ?? ''}
    requestParams={requestParams}
  />
)

export default SelectValidate
