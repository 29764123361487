import React from 'react'

import { DividerType } from './types/Divider.types'

import style from './style/divider.module.scss'

const Divider: React.FC<DividerType> = ({ space, noBorder }) => (
  <div
    className={`${style.divider} ${noBorder ? style.noBorder : ''}`}
    style={{
      margin: space ? `${space}px 0px` : '10px 0px',
    }}
  />
)

export default Divider
