import restfulBase from '@/providers/accessControl/base'
import { ObjectType } from '@/shareds/types'
import { User } from '@/providers/accessControl/endpoints/users'

export enum TYPES {
  MFA_DISABLE = 'mfa-disable',
  TOTP_RESET = 'totp-reset',
  PASSWORD_RECOVERY = 'password-recovery',
  REGISTRATION = 'registration',
}

export const TYPES_TRANSLATE = {
  [TYPES.MFA_DISABLE]: 'Autenticação em dois fatores desabilitado',
  [TYPES.TOTP_RESET]: 'Redefinição de autenticação em dois fatores',
  [TYPES.PASSWORD_RECOVERY]: 'Redefinição senha',
  [TYPES.REGISTRATION]: 'Registro',
}

export interface EmailConfirmation {
  userId?: string
  id: string
  type: TYPES
  isExpired: boolean
  confirmedAt: string
  createdAt: string
  expiresAt: string
  createdByUser: User
  user: User
  include: 'user' | 'createdByUser'
  sort?: string | `-${string}`
}

export const userMessageErrorByMessageErrorRequest: ObjectType = {
  'E-mail address already registered for another user': 'Endereço de e-mail já registrado por outro usuário',
}

export const emailConfirmationProvider = restfulBase<EmailConfirmation>('auth/email-confirmations');
