import { getTokenPayload } from '@/utils/authentication'

export enum PERMISSIONS {
  BROKER_TOTP_RESET = 'broker_totp:reset',
  BROKER_USER_CREATE = 'broker_user:create',
  BROKER_USER_DELETE = 'broker_user:delete',
  BROKER_USER_READ = 'broker_user:read',
  BROKER_USER_UPDATE = 'broker_user:update',
  COMPANY_TOTP_RESET = 'company_totp:reset',
  COMPANY_USER_CREATE = 'company_user:create',
  COMPANY_USER_DELETE = 'company_user:delete',
  COMPANY_USER_READ = 'company_user:read',
  COMPANY_USER_UPDATE = 'company_user:update',
  CONTRACT_CANCEL = 'contract:cancel',
  CONTRACT_REMOVE_FROM_COMPANY = 'contract:remove_from_company',
  MOVE_CREATE = 'movimentation:create',
  BROKER_USER_RESTORE = 'broker_user:restore',
  COMPANY_USER_RESTORE = 'company_user:restore',
}

export const checkPermission = (permission: string): boolean => {
  const { user } = getTokenPayload() || {}
  return (user?.permissions || []).includes(permission)
}
