import { USER_LIST_FILTERS } from '@/pages/users/list/entities/user.enums';
import { ParamsToFilterUser } from '@/pages/users/list/entities/user.interfaces';
import useUserFilterFields from '@/pages/users/list/hooks/useUserFilterFields';
import React from 'react';
import { BoxInfo, GridGroup } from '@/components';

interface UserFiltersProps {
  fields: {
    fieldName: USER_LIST_FILTERS,
    size?: number
  }[];
  onChange: (params: ParamsToFilterUser) => void;
  filters: ParamsToFilterUser;
}

const Filter: React.FC<UserFiltersProps> = ({ fields, onChange, filters }) => {
  const { fieldsToChoose } = useUserFilterFields({
    onChange,
    filters
  })

  return (
    <BoxInfo>
      <GridGroup
        body={fields.map(field => ({
          ...fieldsToChoose[field.fieldName],
          default: field.size
        }))}
      />
    </BoxInfo>
  )
}

export default Filter
