import React from 'react'

import Button from 'shared/components/button'
import SliderSwiper from 'shared/components/context/sliderSwiper/sliderSwiper'
import Text from 'shared/components/text'
import classesBuilder from 'shared/utils/classesBuilder'

import ToastType from '../types/toast.type'

import { UseDuration, UseLayer } from './hooks'

import { Grid, Row } from 'context'
import style from 'theme/components/toast/toast.module.scss'

const Layer: React.FC<ToastType> = ({
  action,
  align,
  elevation = 2,
  duration,
  icon,
  iconDir,
  instanceEmitter,
  position,
  outlined = true,
  rounded,
  shadow,
  text,
  title,
  titleColor,
  type,
  complementary,
}) => {
  const { useOpenLayer, useShowLayer, onOpenLayer, onCloseLayer } = UseLayer()
  const useRestDuration = UseDuration({
    duration,
    useShowLayer,
    onCloseLayer,
  })

  const iconNames = {
    success: 'checkmark-circle-outline',
    danger: 'alert-circle-outline',
    warning: 'alert-triangle-outline',
    info: 'info-outline',
  }

  const classNamesLayer = {
    [`elevation${elevation}`]: elevation,
    layer: true,
    outlined,
    rounded,
    shadow,
    show: useShowLayer,
  }

  const classNamesTimeDurationProgress = {
    progress: true,
    [type || '']: type,
  }

  if (instanceEmitter) {
    Object.assign(instanceEmitter, {
      open: onOpenLayer,
      dismiss: onCloseLayer,
    })
  }

  const getRefToast = (ref: HTMLDivElement): void => {
    if (ref) {
      const { offsetHeight } = ref

      if (!useShowLayer) {
        if (align === 'center') {
          if (position && position !== 'center') {
            ref.style[position] = `${offsetHeight * -1}px`
          }
        }
      } else {
        ref.style.height = `${offsetHeight}px`
      }
    }
  }

  const onSlidingLayer = ({ isClosed }: { isClosed: boolean }): void => {
    if (isClosed) {
      setTimeout(onCloseLayer, 200)
    }
  }

  const { label, icon: iconAction, solo, onClick } = action || {}
  const transitionDuration = `width ${duration}s linear`

  return useOpenLayer ? (
    <div ref={getRefToast} className={classesBuilder(style, classNamesLayer)}>
      <SliderSwiper
        {...(align === 'center'
          ? { axisY: position === 'center' ? true : position }
          : { axisX: align })}
        sliding={!onClick}
        onSliding={onSlidingLayer}
        parentMove
      >
        <div className={style.context}>
          <div className={style.action}>
            <Button
              solo={solo}
              color={type}
              iconColor="clear"
              icon={iconAction || (!label && 'close-outline') || ''}
              label={label}
              size="larger"
              onClick={() => (onClick ? onClick() : onCloseLayer())}
            />
          </div>
          <div className={style.info}>
            {title ? (
              <Text
                value={title}
                color={titleColor}
                margin="0 0 16px 0"
                icon={icon || (type && type !== 'default' && iconNames[type]) || ''}
                iconColor={type}
                iconDir={iconDir}
                iconSize={24}
                size="medium"
                title
              />
            ) : null}
            <Text size="larger" value={text} />
          </div>
          {complementary && (
            <div className={style.info}>
              <Row style={{ padding: '12px 0' }}>
                <Grid gap={16} alignItems="center" alignContent="left">
                  <Text value={complementary?.text ?? ''} color={titleColor} size="medium" />
                  <Grid>
                    <Button
                      invertColor
                      color={type}
                      iconColor={type}
                      icon={complementary?.action?.icon ?? ''}
                      label={complementary?.action?.label}
                      size="small"
                      onClick={() =>
                        complementary?.action?.onClick
                          ? complementary?.action?.onClick()
                          : undefined
                      }
                    />
                  </Grid>
                </Grid>
              </Row>
            </div>
          )}
        </div>
        {duration ? (
          <div className={style.timeDuration}>
            <div
              className={classesBuilder(style, classNamesTimeDurationProgress)}
              style={{
                width: `${useRestDuration}%`,
                WebkitTransition: transitionDuration,
                MozTransition: transitionDuration,
                OTransition: transitionDuration,
                transition: transitionDuration,
              }}
            />
          </div>
        ) : null}
      </SliderSwiper>
    </div>
  ) : (
    <div className={style.layerEmpty} />
  )
}

export default Layer
