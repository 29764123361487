import { Location } from 'history';
import { useHistory, useLocation, useParams } from 'react-router-dom'

export const useHistoryNavigator = (): {
  goBack: Function
  push: Function
  replace: Function
} => {
  const history = useHistory()

  return {
    replace: history.replace,
    goBack: () => history.go(-1),
    push: (to: string, props: any) => history.push(to, { ...props }),
  }
}

export const useHistoryLocation = (): Location => useLocation()

export const useHistoryParams = (): any => useParams()

export const UseHistoryQuery = (): any =>
  (useLocation().search || '')
    .replace(/^\?/, '')
    .split('&')
    .reduce((acc: { [key: string]: string }, item: string) => {
      const [key, value] = item.split('=')

      return {
        ...acc,
        [key]: value,
      }
    }, {})
