import React from 'react'

import { useHistoryNavigator } from '@/navigation'
import { Row } from 'vkit/lib/context'
import { Text } from 'vkit/lib/components'

const ButtonGoBack: React.FC = () => {
  const navigator = useHistoryNavigator()

  return (
    <Row
      style={{
        display: 'block',
        padding: '0 0 16px',
        marginLeft: -8,
        cursor: 'pointer',
      }}
    >
      <div onClick={() => navigator.goBack()} data-testid='buttonGoBack'>
        <Text icon='arrow-ios-back-outline' iconSize={30} size='medium' title value='Voltar' />
      </div>
    </Row>
  )
}

export default ButtonGoBack
