import { List } from '@/pages/users/list'
import { CreateOrUpdate } from '@/pages/users/createOrUpdate'
import { Details } from '@/pages/users/Details'

const route = {
  path: '/:scopeType/:scopeId/users',
  nameMenu: 'Usuários',
  icon: 'people-outline',
  exact: true,
  component: List,
  children: [
    {
      path: 'create',
      nameMenu: 'Criação de usuário',
      icon: 'people-outline',
      exact: true,
      component: CreateOrUpdate,
    },
    {
      path: ':userId/details',
      nameMenu: 'Detalhes do usuário',
      icon: 'people-outline',
      exact: true,
      component: Details,
    },
  ],
}

export default route
