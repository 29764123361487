import restfulBase from '@/providers/accessControl/base'
import { Scopes } from '@/providers/accessControl/endpoints/users'

export enum FILES_PERMISSION_ID {
  CREATE = 'files/dir:create',
  READ = 'files/dir:read',
  DELETE = 'files/dir:delete'
}

export interface Permission {
  id: string
  name: string
  type: Scopes
  createdAt: string | null
  deletedAt: string | null
  updatedAt: string | null
  scoped: boolean | null
  description?: string | null
}

export const permissionsProvider = restfulBase<Permission>('/permissions')
