import { FILES_PERMISSION_ID } from '@/providers/accessControl/endpoints/permissions';
import { User, UserPermission, UserPermissionPayload } from '@/providers/accessControl/endpoints/users';
import categoriesProvider from '@/providers/files/endpoints/categories';
import { useCallback, useEffect, useState } from 'react';
import { ListCategories, normalizeCategories } from '../utils';
import useDisabledManage, { UseDisabledManage } from './useDisabledManage';

interface UseFileCategoryPermissionsProps {
  initialPermissions: UserPermission[],
  userId?: string,
  currentUser?: User
  disabled?: boolean
}

interface UseFileCategoryPermissions extends Omit<UseDisabledManage, 'shouldDisableAll'> {
  loading: boolean
  data: ListCategories[],
  listUserPermissions: UserPermissionPayload[],
  handleBlockPermission: (id: string, type: FILES_PERMISSION_ID, check: boolean) => void
  handleItemPermission: (parentId: string, id: string, type: FILES_PERMISSION_ID) => void
}

export type FilePermissionsId = `${FILES_PERMISSION_ID}`;

const useFileCategoryPermissions = (
  { userId, currentUser, initialPermissions, disabled }: UseFileCategoryPermissionsProps
  ): UseFileCategoryPermissions => {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ data, setData ] = useState<ListCategories[]>([]);
  const [ listUserPermissions, setUserPermission ] = useState<UserPermissionPayload[]>([]);

  const { shouldDisableCurrentItem, shouldDisableParent } = useDisabledManage({
    userId,
    currentUser,
    currentData: data,
    disabled
  });

  const permissionActions:
    Record<FILES_PERMISSION_ID,(item: ListCategories, check: boolean) => void> = {
      [FILES_PERMISSION_ID.CREATE]: (item, check) => {
        item.hasAddPermission = check;
        if (check) {
          item.hasViewPermission = check;
        }
      },
      [FILES_PERMISSION_ID.DELETE]: (item, check) => {
        item.hasDeletePermission = check;
      },
      [FILES_PERMISSION_ID.READ]: (item, check) => {
        item.hasViewPermission = check;
      },
    };

  const currentPermissionValue: Record<FILES_PERMISSION_ID, (item: ListCategories) => boolean > = {
    [FILES_PERMISSION_ID.CREATE]: (item) => {
      return item.hasAddPermission;
    },
    [FILES_PERMISSION_ID.DELETE]: (item) => {
      return item.hasDeletePermission;
    },
    [FILES_PERMISSION_ID.READ]: (item) => {
      return item.hasViewPermission;
    },
  }

  const createUserPermissionPayload = (id: string, permission: FILES_PERMISSION_ID)
    : UserPermissionPayload  => {
    return { scope: id, permissionId: permission };
  }

  const processChildren = useCallback((category: ListCategories): UserPermissionPayload[] => {
    let permissions: UserPermissionPayload[] = [];

    if (category.hasAddPermission) {
      permissions.push(createUserPermissionPayload(category.id, FILES_PERMISSION_ID.CREATE));
    }
    if (category.hasViewPermission) {
      permissions.push(createUserPermissionPayload(category.id, FILES_PERMISSION_ID.READ));
    }
    if (category.hasDeletePermission) {
      permissions.push(createUserPermissionPayload(category.id, FILES_PERMISSION_ID.DELETE));
    }

    return permissions;
  }, []);

  const handlePayloadPermissions = useCallback((): void => {
    let permissions: UserPermissionPayload[] = [];

    data.forEach((category) => {
      let childPermissions: UserPermissionPayload[] = [];
      category.children.forEach((child) => {
        childPermissions = childPermissions.concat(processChildren(child));
      })
      if (childPermissions.length) {
        permissions = [
          ...permissions,
          createUserPermissionPayload(category.id, FILES_PERMISSION_ID.READ),
          ...childPermissions
        ];
      }
    });
    setUserPermission(permissions.flat());
  }, [data, processChildren]);

  const handleItemPermission = (parentId: string, id: string, type: FILES_PERMISSION_ID): void => {
    const updatedData = data.map((blockPermission) => {

      if (parentId === blockPermission.id) {
        const children = blockPermission.children.map((item) => {
          if (item.id === id) {
            const action = permissionActions[type];
            const currentPermission = currentPermissionValue[type](item);
            if (action && !shouldDisableCurrentItem(item.id, type)) {
              action(item, !currentPermission);
            }
          }
          return item;
        })
        blockPermission.children = children;
      }

      return blockPermission;
    });

    setData(updatedData)
  }

  const handleBlockPermission = (id: string, type: FILES_PERMISSION_ID, check: boolean): void => {
    const updatedData = data.map((blockPermission) => {
      if (id === blockPermission.id) {
        const children = blockPermission.children.map((item) => {
          const action = permissionActions[type];
          if (action && !shouldDisableCurrentItem(item.id, type)) {
            action(item, check);
          }
          return item;
        });

        blockPermission.children = children;
      }

      return blockPermission;
    });

    setData(updatedData);
  };

  const loadPermissions = useCallback(async() => {
    setLoading(true);

    try {
      const response = await categoriesProvider.index();
      setData(normalizeCategories(response.data, initialPermissions));
    } catch (e) {
      console.error(e)
    } finally {
      handlePayloadPermissions();
      setLoading(false);
    }
  }, [handlePayloadPermissions, initialPermissions])

  useEffect(() => {
    if (!data.length) {
      loadPermissions();
    }
    if (data) {
      handlePayloadPermissions();
    }
  }, [data, handlePayloadPermissions, loadPermissions])

  return {
    loading,
    data,
    listUserPermissions,
    handleBlockPermission,
    handleItemPermission,
    shouldDisableCurrentItem,
    shouldDisableParent
  }

}

export default useFileCategoryPermissions;
