import { useState, useCallback, useEffect } from 'react'
interface UseImageInterface {
  alt?: string
  src?: string
  imageDefaultUrl?: string
}

const UseImage = ({
  alt,
  src,
  imageDefaultUrl,
}: UseImageInterface): {
  useAlt: string
  useImage: string
  useEmptyImage: boolean
} => {
  const [useImage, setImage] = useState('')
  const [useEmptyImage, setEmptyImage] = useState(false)
  const [useAlt, setAlt] = useState('')

  const getMapAvatarName = useCallback((): void => {
    if (alt) {
      const breakName = alt.split(' ')
      const onlyOneSurname = breakName?.[1]?.substr(0, 1) || ''
      setAlt(alt.substr(0, 1) + onlyOneSurname)
    }
  }, [alt])

  const onError = useCallback((): void => {
    if (imageDefaultUrl) {
      setImage(imageDefaultUrl)
      return
    }
    setEmptyImage(true)
    getMapAvatarName()
  }, [getMapAvatarName, imageDefaultUrl])

  useEffect(() => {
    if (src) {
      const image = new Image()
      image.onload = () => {
        setImage(src)
      }
      image.onerror = onError
      image.src = src
    } else if (alt || (src !== '' && alt !== '')) {
      onError()
    }
  }, [alt, onError, src])

  return { useAlt, useImage, useEmptyImage }
}

export default UseImage
