import useClickOnOutside from './useClickOnOutside'
import useFloatLayerBounding from './useFloatLayerBounding'
import useShowOpen from './useShowOpen'

interface useFloatLayerInterface {
  canShow: boolean
  onClose: Function
  closeClickOutside: boolean
}

const useFloatLayer = ({
  canShow,
  onClose,
  closeClickOutside,
}: useFloatLayerInterface): {
  getElementHeight: ({}: HTMLDivElement) => void
  useTop: number | 'auto'
  useHeight: number
  useOpen: boolean
  useShow: boolean
  useWidth: number
  wrapperRef: { current: HTMLDivElement | null }
} => {
  const { useOpen, useShow } = useShowOpen({ canShow })

  const { ref: wrapperRef } = useClickOnOutside(() => closeClickOutside && onClose(), useShow)

  const { getElementHeight, useHeight, useWidth, useTop } = useFloatLayerBounding()

  return {
    getElementHeight,
    useTop,
    useHeight,
    useOpen,
    useShow,
    useWidth,
    wrapperRef,
  }
}

export default useFloatLayer
