import React, { ReactNode, useImperativeHandle, ForwardRefRenderFunction } from 'react'
import useFormValidates from './hooks/useFormValidates'
import { Button } from 'vkit/lib/components'
import { Grid, Tooltip } from 'vkit/lib/context';
import { validateNamesType, FormValidateHandler } from './types/formValidate.type'

interface FormValidateInterface {
  objectName?: string
  resource: any
  resourceDefault?: any
  fields: {
    [key: string]: (validateNamesType | Function)[]
  }
  body: (...item: any) => ReactNode
  onBeforeSubmit?: Function
  onSubmit: Function
  onChangeForm?: Function
  onBack?: Function
  onError?: Function
  buttons?: {
    submit?: {
      icon?: string
      text?: string
      iconDir?: 'left' | 'right'
      loading?: boolean
      disabled?: boolean
      tooltip?: string | null
    }
    back?: {
      icon?: string
      text?: string
      iconDir?: 'left' | 'right'
    }
  }
  clearNextFields?: boolean
}

const FormValidate: ForwardRefRenderFunction<FormValidateHandler, FormValidateInterface> = (
  props,
  forwardedRef,
) => {
  const {
    objectName,
    resource,
    resourceDefault,
    buttons,
    fields,
    body,
    onChangeForm,
    onSubmit,
    onBack,
    onBeforeSubmit,
    onError,
    clearNextFields,
  } = props

  const { onBlur, onChange, onValidateForm, useErrors, useValidate, useValues, updateFieldErrors } =
    useFormValidates({
      fields,
      fnCallback: onSubmit,
      onChangeForm,
      onError,
      onBeforeValidateForm: onBeforeSubmit,
      objectName,
      resource,
      resourceDefault,
      clearNextFields,
    })

  useImperativeHandle(forwardedRef, () => ({
    async submit() {
      return onValidateForm()
    },
  }))

  return (
    <form onSubmit={onValidateForm} autoComplete='off'>
      {body({
        onBlur,
        onChange,
        useErrors,
        useValidate,
        useValues,
        updateFieldErrors,
      })}

      {buttons && (
        <Grid alignContent='right' margin='36px 0 0'>
          {buttons.back && (
            <Button
              icon={buttons.back.icon}
              iconDir={buttons.back.iconDir}
              solo
              label={buttons.back.text}
              onClick={() => onBack?.(useValues)}
            />
          )}

          {buttons.submit && !buttons.submit.tooltip && (
            <Button
              icon={buttons.submit.icon}
              iconDir={buttons.submit.iconDir}
              label={buttons.submit.text}
              submit
              loading={buttons.submit.loading}
              disabled={buttons.submit.disabled}
            />
          )}
    
          {buttons.submit && buttons.submit.tooltip && (
            <Tooltip text={buttons.submit.tooltip}>
              <Button
                icon={buttons.submit.icon}
                iconDir={buttons.submit.iconDir}
                label={buttons.submit.text}
                submit
                loading={buttons.submit.loading}
                disabled={buttons.submit.disabled}
              />
            </Tooltip>
          )}
        </Grid>
      )}
    </form>
  )
}

export default React.forwardRef(FormValidate)
