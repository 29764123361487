import { useHistoryNavigator } from '@/navigation';
import { USER_STATUS } from '@/pages/users/list/entities/user.enums';
import { User } from '@/providers/accessControl/endpoints/users';
import React from 'react';

import { Button } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface ActionsListProps {
  user: User
}

const ActionsList: React.FC<ActionsListProps> = ({ user }) => {
  const navigator = useHistoryNavigator()

  const handleUserDetailsNavigation = (): void => {
    navigator.push(
      user.status === USER_STATUS.INACTIVATED
        ? `deleted-users/${user.id}/details`
        : `users/${user.id}/details`
    )
  }
  
  return (
    <Grid alignContent='center'>
      <Button
        icon='eye-outline'
        iconColor='default'
        label='Detalhes'
        onClick={() => handleUserDetailsNavigation() }
        solo
        outlined
      />
    </Grid>
  )
}

export default ActionsList
