import React from 'react'

import { BoxInfo, CustomList } from '@/components'
import { useHistoryParams } from '@/navigation'

import useUserHistory from './hooks/useUserHistory'
import { Row } from 'vkit/lib/context'

const innerHeight = window.innerWidth > 768 ? 60 : 80

const ListRequestHistory: React.FC = () => {
  const useParams = useHistoryParams();

  const {
    isLoading,
    useListRef,
    getColumnData,
    getData
  } = useUserHistory(useParams.userId)

  return (
    <BoxInfo elevation={1} margin={0}>
      <Row
        style={{
          padding: 0,
          margin: '-19px',
        }}
      >
        <CustomList
          blur={isLoading}
          listRef={useListRef}
          size={(getData || []).length || 3}
          data={getData}
          innerHeight={innerHeight}
          dataList={getColumnData}
        />
      </Row>
    </BoxInfo>
  )
}

export default ListRequestHistory
