const email = (email: string): string | null => {
  if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email) ||
    email === ''
  ) {
    return null
  }

  return 'Email inválido'
}

export default email
