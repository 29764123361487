import React, { useCallback, useEffect } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import PrivateRoute from './components/privateRoute'
import { ToastStatus } from '@/components'
import { permissionsProvider } from '@/providers/accessControl/endpoints/permissions'

const App: React.FC = () => {
  const { toastComponent, toast } = ToastStatus()
  
  const loadPermissions = useCallback(async (): Promise<void> => {
    try {
      const permissions = await permissionsProvider.list()
      localStorage.setItem('permissions', JSON.stringify(permissions || []))
    } catch (error) {
      console.error(error)
      toast('Oops!', 'erro ao carregar permissões', 'error')
    }
  }, [toast])
  
  useEffect(() => {
    (async () => loadPermissions())()
  }, [loadPermissions])
  
  return (
    <BrowserRouter>
      {toastComponent}
      
      <Switch>
        <PrivateRoute />
      </Switch>
    </BrowserRouter>
  )
}

export default App
