import React from 'react'
import { Route } from 'react-router-dom'
import { StructurePage } from 'vkit/lib/modules'
import routesGroups from '../../routes'

const PrivateRoute: React.FC = () => (
  <Route
    render={(props) => (
      <StructurePage
        routesGroups={routesGroups}
        options={{
          user: {
            name: 'Vittana(o)',
            photo: '',
          },
          description: {
            portal: 'Admin V2',
            general: `${new Date().getFullYear()} © Vitta Coordenação em Saúde`,
            version: 'v0.1.0',
          },
        }}
        {...props}
      />
    )}
  />
)

export default PrivateRoute
