import { get } from 'lodash'
import { ObjectType } from '@/shareds/types'
export interface TreatErrorOptions {
  error: unknown
  messageDefault?: string
  messageErrorByMessageErrorRequest?: ObjectType
  pathMessageRequest?: string
}

export interface IUseTreatError {
  toastError: (options: TreatErrorOptions) => void
}

export const toastError = (
  {
    error,
    messageDefault,
    messageErrorByMessageErrorRequest,
    pathMessageRequest = 'response.data.message',
  }: TreatErrorOptions
): void => {
  console.error(error)
  const messageErrorRequest = get(error, pathMessageRequest, '')
  
  const messageErrorKey = Object
    .keys(messageErrorByMessageErrorRequest || {})
    .find((key) => new RegExp(key, 'i').test(messageErrorRequest))
  
  const messageError = messageErrorKey && messageErrorByMessageErrorRequest?.[messageErrorKey]
    || messageErrorRequest
    || messageDefault

  const toastEvent = new CustomEvent('toast', {
    detail: {
      icon: 'close-circle-outline',
      title: 'Oops!',
      text: messageError,
      type: 'danger',
      align: 'right',
      titleColor: 'danger',
      position: 'bottom',
      shadow: true,
      duration: 10,
    }
  })
  
  window.dispatchEvent(toastEvent)
}
