import { Details } from '@/pages/users/Details'

const route = {
  path: '/:scopeType/:scopeId/deleted-users/:userId/details',
  nameMenu: 'Detalhes de usuário deletado',
  icon: 'people-outline',
  exact: true,
  component: Details,
}

export default route
