import React from 'react';
import { CreateOrUpdate } from '../createOrUpdate';
import { ListRequestHistory } from './components';
import { BoxInfo } from '@/components'

const Details: React.FC = () => {

  return (
    <CreateOrUpdate
      footer={
        <BoxInfo
          title='Histórico'
          icon='clock-outline'
        >
          <ListRequestHistory />
        </BoxInfo>
      }
    />
  )
}

export default Details
