import { useState, useCallback, useEffect } from 'react'

interface UseDialogInterface {
  isAutoOpen?: boolean
  onClose: Function
}

const UseDialog = ({
  isAutoOpen,
  onClose,
}: UseDialogInterface): {
  useOpenDialog: boolean
  usePersistent: boolean
  useShowDialog: boolean
  onOpenDialog: () => void
  onCloseDialog: Function
} => {
  const [useOpenDialog, setOpenDialog] = useState(false)
  const [usePersistent, setPersistent] = useState(false)
  const [useShowDialog, setShowDialog] = useState(false)

  const onOpenDialog = useCallback(() => {
    setOpenDialog(true)
    setTimeout(() => {
      setShowDialog(true)
    })
  }, [])

  const onCloseDialog = useCallback(
    (isLock: boolean) => {
      if (!isLock) {
        setShowDialog(false)
        setTimeout(() => {
          setOpenDialog(false)
        }, 500)
        onClose()
      } else {
        setPersistent(true)
        setTimeout(() => {
          setPersistent(false)
        }, 500)
      }
    },
    [onClose],
  )

  useEffect(() => {
    if (isAutoOpen) {
      onOpenDialog()
    }
  }, [isAutoOpen, onOpenDialog])

  return {
    useOpenDialog,
    usePersistent,
    useShowDialog,
    onOpenDialog,
    onCloseDialog,
  }
}

export default UseDialog
