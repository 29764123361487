import { Permission, permissionsProvider } from '@/providers/accessControl/endpoints/permissions'
import { UserPermission } from '@/providers/accessControl/endpoints/users'

export const getPermissions = async (): Promise<Permission[]> => {
  const permissionslocalStorage = localStorage.getItem('permissions')
  const permissions = permissionslocalStorage
    ? JSON.parse(permissionslocalStorage) as Permission[]
    : await permissionsProvider.list()

  if (!permissionslocalStorage) {
    localStorage.setItem('permissions', JSON.stringify(permissions))
  }

  return permissions
}

export const getPermissionsByIds = async (ids: UserPermission[]): Promise<Permission[]> => {
  const permissions = await getPermissions()
  return permissions.filter(({ id }) =>
    ids.find((userPermission) => id === userPermission.permissionId && !userPermission.scoped));
}
