import { USER_STATUS_TRANSLATED } from '@/pages/users/list/entities/user.constants';
import { USER_STATUS } from '@/pages/users/list/entities/user.enums';
import React from 'react';
import { Select } from 'vkit/lib/components';
import DataType from 'vkit/lib/components/webform/select/types/data.type';

interface SelectStatusProps {
  onChange: (status?: USER_STATUS[]) => void;
  value?: USER_STATUS[];
}

const SelectStatus: React.FC<SelectStatusProps> = (
  {
    onChange,
    value,
  }
) => {
  const selectOptions: DataType[] = Object.entries(USER_STATUS_TRANSLATED).map(([value, text]) => ({
    value,
    text,
  }))

  return (
    <Select
      label='Status'
      multiple
      clearable
      small
      value={value || []}
      data={selectOptions}
      onChange={onChange}
    />
  );
};

export default SelectStatus;
