type SexType = 'Masculino' | 'Feminino' | 'Não informado'

type StatusMaritalType =
  | 'Casado(a)'
  | 'Divorciado(a)'
  | 'Outro'
  | 'Relacionamento estável'
  | 'Solteiro(a)'
  | 'Viúvo(a)'
  | 'Não informado'

type RelationshipType = 'Cônjuge' | 'Filho(a)' | 'Pai/Mãe' | 'Não informado'

type ActionMoveType = 'Registro' | 'Atualização' | 'Cancelamento'

type subtitleType = {
  actionMove: { [key: string]: ActionMoveType }
  marital: { [key: string]: StatusMaritalType }
  relationship: { [key: string]: RelationshipType }
  sex: { [key: string]: SexType }
  boolean: { [key: string]: string }
}

const subtitle: subtitleType = {
  actionMove: {
    create: 'Registro',
    update: 'Atualização',
    delete: 'Cancelamento',
  },

  sex: {
    male: 'Masculino',
    female: 'Feminino',
    m: 'Masculino',
    f: 'Feminino',
  },

  marital: {
    single: 'Solteiro(a)',
    married: 'Casado(a)',
    divorced: 'Divorciado(a)',
    widowed: 'Viúvo(a)',
    'stable-relationship': 'Relacionamento estável',
  },

  relationship: {
    parent: 'Pai/Mãe',
    child: 'Filho(a)',
    spouse: 'Cônjuge',
  },

  boolean: {
    true: 'Sim',
    false: 'Não',
  },
}

const translate = {
  actionMove: (key: string): ActionMoveType => subtitle.actionMove[key],
  marital: (key: string): StatusMaritalType => subtitle.marital[key],
  relationship: (key: string): RelationshipType => subtitle.relationship[key],
  sex: (key: string): SexType => subtitle.sex[key],
  boolean: (key: string): string => subtitle.boolean[key],
}

export default translate
