import { USER_STATUS_TRANSLATED } from '@/pages/users/list/entities/user.constants';
import { USER_STATUS } from '@/pages/users/list/entities/user.enums';
import React from 'react'
import { Chip } from 'vkit/lib/components'

interface ChipStatusProps {
  status: USER_STATUS
}

const ChipStatus: React.FC<ChipStatusProps> = ({
  status
}) => {
  return (
    <Chip
      color='light'
      label={USER_STATUS_TRANSLATED[status]}
      size='small'
      rounded
    />
  )
}

export default ChipStatus
