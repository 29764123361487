import TextFieldType from '../types/textField.type'
import { StatusEnum } from '../../builder/enums'
import { ReactNode } from 'react'
import { InputEnum } from '../../builder/container/field/types/field.type'

export enum TagNameEnum {
  input = 'input',
  textarea = 'textarea',
}

export type FieldBaseType = TextFieldType & {
  handleBeforeChange?: Function
  inputType?: InputEnum
  tagName?: TagNameEnum
  setTtfStatus: Function
  setTtfTextHelper: Function
  setTtfValue: Function
  statusOrigin?: StatusEnum
  contentRight?: ReactNode
  mask?: string
}

export default FieldBaseType
