import { SelectOption } from '@/components/webForm/selectValidate';
import { USER_STATUS } from '@/pages/users/list/entities/user.enums';
import { Permission } from '@/providers/accessControl/endpoints/permissions';
import {
  deletedUsersProvider,
  User,
  userInitialState,
  UserPermission,
  usersProvider
} from '@/providers/accessControl/endpoints/users';
import { ObjectType } from '@/shareds/types';
import { getTokenPayload } from '@/utils/authentication';
import { getPermissions } from '@/utils/permission';
import { useCallback, useEffect, useState } from 'react';

export interface IUseCreate {
  useCurrentStep: string
  useData: User
  onUpdate: Function
  setCurrentStep: Function
  useLoading: boolean
  usePermissionOptions: SelectOption[]
  userPermissionScoped: UserPermission[]
}

export interface UseCreateProps {
  userId: string
  isDeletedUserRoute?: boolean
}

export const UseCreateOrUpdate = (
  {
    userId,
    isDeletedUserRoute,
  }: UseCreateProps
): IUseCreate => {
  const [useCurrentStep, setCurrentStep] = useState('')
  const [useData, setData] = useState<User>(userInitialState)
  const [useLoading, setLoading] = useState(false)
  const [usePermissionOptions, setPermissionOptions] = useState<SelectOption[]>([])
  const [userPermissionScoped, setPermissionScoped ] = useState<UserPermission[]>([]);

  const onUpdate = useCallback(
    (update: ObjectType) => {
      setData((currentData) => ({
        ...currentData,
        ...update,
      }))
    },
    [],
  )

  const loadUser = useCallback(
    async (): Promise<void> => {
      setLoading(true)
      try {
        const user = isDeletedUserRoute
          ? await deletedUsersProvider.find(userId, { include: 'userPermissions' })
          : await usersProvider.find(userId, {
            include: 'userPermissions',
            withDeleted: true,
          })
        const permissionsWithoutScope = user.userPermissions.filter(
          (permission) => !permission.scope).map((permission) => permission.permissionId);
        const permissionWithScope = user.userPermissions.filter((permission) => !!permission.scope);

        setPermissionScoped(permissionWithScope);
        setData({
          ...user,
          permissions: permissionsWithoutScope,
        })
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [isDeletedUserRoute, userId],
  )

  const permissionsToOptions = useCallback((permissions: Permission[]): SelectOption[] => {
    const decodedToken = getTokenPayload();
    const currLoggedUserPermissions = decodedToken?.user.permissions || [];

    return permissions.filter((permission) => !permission.scoped).map((permission) => {
      const isDisabled = !currLoggedUserPermissions.includes(permission.id);
      return {
        text: permission.name,
        value: permission.id,
        disabled: isDisabled,
        title: isDisabled ? 'Você não pode adicionar ou alterar esta permissão!' : undefined
      }
    })
  }, [])

  const loadPermissionOptions = useCallback(
    async (): Promise<void> => {
      setLoading(true)
      try {
        const permissions = await getPermissions()
        if (permissions) {
          const permissionOptions = permissionsToOptions(permissions)
          setPermissionOptions(permissionOptions)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [permissionsToOptions],
  )

  useEffect(() => {
    (async () => {
      if (userId) {
        await loadUser()
      } else {
        onUpdate({ status: USER_STATUS.PENDING })
      }
    })()
  }, [userId, loadUser, onUpdate])

  useEffect(() => {
    (async () => loadPermissionOptions())()
  }, [loadPermissionOptions])

  useEffect(() => setCurrentStep('infoForm'), [])

  return {
    onUpdate,
    setCurrentStep,
    useCurrentStep,
    useData,
    useLoading,
    usePermissionOptions,
    userPermissionScoped
  }
}

export default UseCreateOrUpdate
