const name = (fieldValue: string): string | null => {
  if (!fieldValue.trim()) {
    return null
  }

  if (/[^a-zà-ú-'\s]/i.test(fieldValue)) {
    return 'Use apenas letras'
  }

  if (!/(.*)\s(.*)/.test(fieldValue.trim())) {
    return 'Precisamos de nome e sobrenome'
  }

  return null
}

export default name
