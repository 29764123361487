import { useMemo, useCallback } from 'react'

interface UsePaginationInterface {
  page: number
  total: number
  itemsPerPage: number
  onChange: Function
  countVisiblePages: number
}

type paginateItemType = {
  type: 'icon' | 'text'
  value: string
  onClick?: () => void
}

const UsePagination = ({
  page,
  total,
  itemsPerPage = 10,
  onChange,
  countVisiblePages = 7,
}: UsePaginationInterface): {
  usePages: paginateItemType[]
} => {
  const getRange = (start: number, end: number): number[] => {
    const length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
  }

  const getPaginate = useCallback(
    (range: (number | '...')[], totalPageCount: number): paginateItemType[] => {
      const paginateItems = range.map((item) => {
        const paginateItem: paginateItemType = {
          type: 'text',
          value: `${item}`,
        }

        if (typeof item === 'number') {
          paginateItem.onClick = () => onChange(item)
        }

        return paginateItem
      })

      return [
        {
          type: 'icon',
          value: 'Anterior',
          ...(page > 1 ? { onClick: () => onChange(page - 1) } : {}),
        },
        ...paginateItems,
        {
          type: 'icon',
          value: 'Próximo',
          ...(page < totalPageCount ? { onClick: () => onChange(page + 1) } : {}),
        },
      ]
    },
    [page, onChange],
  )

  const paginationRange = useMemo((): paginateItemType[] => {
    const totalPageCount = Math.ceil(total / itemsPerPage)
    const amountReservedForEachTip = 2
    const siblingCount = Math.max(
      Math.floor((countVisiblePages - amountReservedForEachTip * 2) / 2),
      1,
    )
    const dots = '...'

    if (countVisiblePages >= totalPageCount) {
      const range = getRange(1, totalPageCount)
      return getPaginate(range, totalPageCount)
    }

    const leftSiblingIndex = Math.max(page - siblingCount, 1)
    const rightSiblingIndex =
      Math.min(page + siblingCount, totalPageCount) + ((countVisiblePages % 2) - 1)

    const canShowLeftDots = leftSiblingIndex > amountReservedForEachTip
    const canShowRightDots = rightSiblingIndex <= totalPageCount - amountReservedForEachTip

    const firstPageIndex = 1

    if (canShowLeftDots && canShowRightDots) {
      const middlegetRange = getRange(leftSiblingIndex, rightSiblingIndex)
      return getPaginate(
        [firstPageIndex, dots, ...middlegetRange, dots, totalPageCount],
        totalPageCount,
      )
    }

    if (canShowRightDots) {
      const leftItemCount = countVisiblePages - amountReservedForEachTip
      const leftgetRange = getRange(firstPageIndex, leftItemCount)

      return getPaginate([...leftgetRange, dots, totalPageCount], totalPageCount)
    }

    if (canShowLeftDots) {
      const rightItemCount = countVisiblePages - amountReservedForEachTip
      const rightgetRange = getRange(totalPageCount - rightItemCount, totalPageCount)

      return getPaginate([firstPageIndex, dots, ...rightgetRange], totalPageCount)
    }

    return []
  }, [total, itemsPerPage, page, countVisiblePages, getPaginate])

  return {
    usePages: paginationRange,
  }
}

export default UsePagination
