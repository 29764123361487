import React from 'react'

import style from './wrapperPage.module.scss'

import { checkMobile, classesBuilder } from '@/utils'

const WrapperPage: React.FC = ({ children }) => (
  <div
    className={classesBuilder(style, {
      wrapperPage: true,
      mobile: checkMobile(),
    })}
  >
    {children}
  </div>
)

export default WrapperPage
