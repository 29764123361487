import restfulBase from '@/providers/accessControl/base'
import { ObjectType } from '@/shareds/types'

export interface TotpReset {
  userId?: string
}

export const totpResetErrors: ObjectType = {
  'E-mail address already registered for another user': 'Endereço de e-mail já registrado por outro usuário.',
  'User already has pending confirmation': 'Usuário já tem uma confirmação pendente.',
  'You cannot reset unconfirmed user totp': 'Você não pode redefinir autenticação em dois fatores para um usuário não confirmado.',
}

export const totpResetProvider = restfulBase<TotpReset, TotpReset>('auth/totp-reset')
