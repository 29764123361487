import React, { ReactNode } from 'react'
import { Box, Grid, Row } from 'vkit/lib/context'
import { Text, Icon, Loader } from 'vkit/lib/components'

import style from './style/boxInfo.module.scss'

type BoxInfoType = {
  children: ReactNode
  header?: ReactNode
  title?: string
  icon?: string
  loading?:boolean
  elevation?: number
  margin?: number
}

const BoxInfo: React.FC<BoxInfoType> = (
  {
    children,
    title,
    icon,
    header,
    loading,
    elevation= 4,
    margin,
  }
) => (
  <div className={style.boxInfo} style={{ margin }}>
    <Box elevation={elevation} type='sheet' outlined rounded>
      <div className={style.content}>
        {
          title || header ? (
            <div className={style.title}>
              <Grid alignItems='center'>
                <div style={{
                  paddingRight: 8
                }}>
                  {
                    icon && !loading && (
                      <Icon name={icon} color='default' />
                    )
                  }

                  {
                    loading && (
                      <Loader color='default' />
                    )
                  }
                </div>

                {title && (
                  <Text
                    color='default'
                    size='small'
                    title
                    value={title}
                  />
                )}
              </Grid>

              {header}
            </div>
          ) : null
        }

        <Row style={{ padding: '19px' }}>{children}</Row>
      </div>
    </Box>
  </div>
)

export default BoxInfo
