import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

window.addEventListener('message', event => {
  const localStorageKeys = [
    'token',
    'title',
    'breadcrumb',
    'vkit-theme'
  ]

  for (const localStorageKey of localStorageKeys) {
    if (event.data[localStorageKey]) {
      localStorage.setItem(localStorageKey, event.data[localStorageKey])
    }
    // Este If foi necessário pois, mesmo recebendo a informação de alteração do tema
    // o componente não conseguia setar ao body o novo atributo.
    // então sempre que ele recebe uma mensagem ele verifica quando é do vkit-theme e atribui o valor
    // que recebeu ao atributo do body.
    if (localStorageKey === 'vkit-theme' && event.data['vkit-theme']) {
      document.body.setAttribute('data-theme', event.data['vkit-theme']);
    }
  }
  
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  )
});
