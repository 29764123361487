import { BoxInfo, DialogConfirm, Pagination, SelectPageSize, WrapperPage } from '@/components';
import useDialogConfirm from '@/components/dialogConfirm/hooks/useDialogConfirm';
import { useHistoryNavigator, useHistoryParams } from '@/navigation';
import { USER_LIST_FILTERS } from '@/pages/users/list/entities/user.enums';
import { ParamsToFilterUser } from '@/pages/users/list/entities/user.interfaces';
import { USER_SCOPE } from '@/shareds/constants/scope';
import { getHistoriesBreadcrumb } from '@/utils/getHistoriesBreadcrumb';
import React, { useMemo } from 'react';
import { Breadcrumb, Button, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';
import { ContentList, Filter } from './components';
import useUsers from './hooks/useUsers';

const List: React.FC = () => {
  const useParams = useHistoryParams()
  const useNavigator = useHistoryNavigator()
  const useTitle = useMemo(() => localStorage.getItem('title') || '', [])
  const useBreadcrumb = useMemo(() => {
    const breadcrumbLocalStorage =
      useParams.scopeType !== USER_SCOPE.BROKER && RegExp('corretoras').test(document.referrer)
        ? localStorage.getItem('breadcrumb')
        : null
    if (!breadcrumbLocalStorage) {
      return null
    }
    
    const historiesBreadcrumb = []
    if (breadcrumbLocalStorage) {
      historiesBreadcrumb.push(
        ...getHistoriesBreadcrumb(JSON.parse(breadcrumbLocalStorage))
      )
    }
    
    historiesBreadcrumb.push({
      name: 'Usuários'
    })
    
    return historiesBreadcrumb
  }, [useParams.scopeType])
  
  const {
    loadUsers,
    useData,
    useListBlur,
    pagination,
    useFilters,
  } = useUsers()

  const {
    useTitle: useDialogConfirmTitle,
    useText: useDialogConfirmText,
    useOpen: useDialogConfirmOpen,
    useFnCallback,
  } = useDialogConfirm()

  return (
    <WrapperPage>
      <DialogConfirm
        fnCallback={useFnCallback}
        open={useDialogConfirmOpen}
        text={useDialogConfirmText}
        title={useDialogConfirmTitle}
      />
  
      { useTitle && (<Text value={useTitle} title />) }
      { useBreadcrumb && (<Breadcrumb historys={useBreadcrumb} />)}

      <Filter
        onChange={loadUsers}
        filters={useFilters as ParamsToFilterUser}
        fields={[
          {
            fieldName: USER_LIST_FILTERS.SEARCH,
            size: 66.66
          },
          {
            fieldName: USER_LIST_FILTERS.STATUS,
            size: 33.33
          }
        ]}
      />

      <Grid alignContent='right' margin='0 0 16px'>
        <Button
          onClick={() => useNavigator.push('users/create')}
          label='Novo usuário'
          color='default'
          icon='person-outline'
        />
      </Grid>
  
      <BoxInfo>
        <Row
          style={{
            padding: 0,
            margin: '-19px',
          }}
        >
          <ContentList
            blur={useListBlur}
            data={useData}
          />
  
          <Row
            style={{
              padding: '0 16px 16px',
              borderTop: '1px solid var(--vkit-color-contrast-1)',
            }}
          >
            <Grid row alignContent='justify' margin='16px 0 0'>
              <SelectPageSize
                onChangePageSize={(pageSize: number) => loadUsers({
                  ...useFilters,
                  page: 1,
                  pageSize,
                })}
                pageSize={pagination.pageSize || 10}
              />
      
              <Pagination
                page={pagination.page || 1}
                total={pagination.total}
                itemsPerPage={useFilters.pageSize}
                onChange={(page: number) => loadUsers({
                  ...useFilters,
                  page
                })}
              />
            </Grid>
          </Row>
        </Row>
      </BoxInfo>
    </WrapperPage>
  )
}

export default List
