const mobileNumber = (value: string): string | null => {
  if (value === '') {
    return null
  }

  const onlyNumbers = value ? value.replace(/^55|\D/g, '') : ''

  if ([10, 11].includes(onlyNumbers.length)) {
    return null
  }

  return 'Celular inválido'
}

export default mobileNumber
