import React from 'react';

import { Company } from '@/providers/negotiations/endpoints/companies';
import { formatText } from '@/utils';
import { Grid, Row } from 'vkit/lib/context';

interface RecursiveListProps {
  list: Company[]
  isChildren?: boolean
}

export const RecursiveList: React.FC<RecursiveListProps> = ({ list, isChildren }) => {

  const useMargin: string = `${!isChildren ? '24px' : '12px'} 0 0 ${isChildren ? '24px' : '0'}`;

  return (
    <Grid column gap={8} margin={ useMargin }>
      {
        list.length > 0 && list.map((company) => (
          <Row key={ company.cnpj } style={{ padding: 0 }}>
            <p><strong> { company.name } </strong></p>
            <p><small> { formatText(company.cnpj, 'cnpj') } </small></p>
            {
              company.children && company.children.length > 0 && (
                <RecursiveList isChildren list={ company.children } />
              )
            }
          </Row>
        ))
      }
    </Grid>
  )
}
