import { ButtonGoBack, Divider, ShowHide, WrapperPage } from '@/components';
import { useHistoryLocation, useHistoryNavigator, useHistoryParams } from '@/navigation';
import { InfoForm } from '@/pages/users/createOrUpdate/forms'
import { Scopes } from '@/providers/accessControl/endpoints/users'
import { getHistoriesBreadcrumb } from '@/utils/getHistoriesBreadcrumb'
import { CompanyList } from '@/views/companyList'
import React, { useMemo } from 'react'
import { Breadcrumb, Text } from 'vkit/lib/components'
import { Row } from 'vkit/lib/context'
import useCreateOrUpdate from './hooks/useCreateOrUpdate'
import { USER_SCOPE } from '@/shareds/constants/scope';

interface CreateOrUpdateProps {
  footer?: React.ReactNode
}

const CreateOrUpdate: React.FC<CreateOrUpdateProps> = ({ footer }) => {
  const { pathname } = useHistoryLocation()
  const useParams = useHistoryParams()
  const navigate = useHistoryNavigator()
  const isDeletedUserRoute = pathname.includes('deleted-users')
  const {
    useCurrentStep,
    useData,
    onUpdate,
    setCurrentStep,
    useLoading,
    usePermissionOptions,
    userPermissionScoped,
  } = useCreateOrUpdate({
    userId: useParams.userId,
    isDeletedUserRoute,
  })

  const useBreadcrumb = useMemo(() => {
    const historiesBreadcrumb = []
    const breadcrumbLocalStorage =
      useParams.scopeType !== USER_SCOPE.BROKER && RegExp('corretoras').test(document.referrer)
        ? localStorage.getItem('breadcrumb')
        : null
    
    if (breadcrumbLocalStorage) {
      historiesBreadcrumb.push(
        ...getHistoriesBreadcrumb(JSON.parse(breadcrumbLocalStorage))
      )
    }
    
    if (useParams.userId) {
      historiesBreadcrumb.push(...[
        {
          name: 'Usuários',
          onClick: () => navigate
            .push(`/${useParams.scopeType}/${useParams.scopeId}/users`),
        },
        {
          name: useData?.name || 'Usuário',
        }, {
          name: 'Detalhes',
        }
      ])
    } else {
      historiesBreadcrumb.push(...[
        {
          name: 'Usuários',
          onClick: () => navigate
            .push(`/${useParams.scopeType}/${useParams.scopeId}/users`),
        },
        {
          name: 'Novo usuário',
        }
      ])
    }
    
    return historiesBreadcrumb
  }, [
    useData,
    navigate,
    useParams.scopeId,
    useParams.scopeType,
    useParams.userId
  ])

  return (
    <WrapperPage>
      <Row style={{ padding: '0 0 16px' }}>
        <Breadcrumb historys={useBreadcrumb} />
      </Row>

      <ButtonGoBack />

      <ShowHide visible={isDeletedUserRoute} transition='slideToDown'>
        <Text
          rounded
          size='medium'
          applyBaseColor
          color='warning'
          iconColor='warning'
          padding='8px 12px'
          icon='alert-triangle-outline'
          value='Este usuário está inativo, portanto a funcionalidade de alteração não é permitida.'
        />
        <Divider noBorder />
      </ShowHide>

      <ShowHide transition='slideToDown' visible={useCurrentStep === 'infoForm'}>
        <InfoForm
          disabled={isDeletedUserRoute}
          loading={useLoading}
          useData={useData}
          onChangeUpdate={onUpdate}
          onChangeStep={setCurrentStep}
          usePermissionOptions={usePermissionOptions}
          userPermissionScoped={userPermissionScoped}
        />
      </ShowHide>
      
      { useParams.scopeType === Scopes.COMPANY && <CompanyList scopeId={ useParams.scopeId }/> }

      {footer}
    </WrapperPage>
  )
}

export default CreateOrUpdate
