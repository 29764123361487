import { useHistoryParams } from '@/navigation';
import { USER_STATUS } from '@/pages/users/list/entities/user.enums';
import { User } from '@/providers/accessControl/endpoints/user';
import { checkPermission, PERMISSIONS } from '@/utils/checkPermission';
import React from 'react';
import { Button } from 'vkit/lib/components';
import { Tooltip } from 'vkit/lib/context';

interface ButtonReactiveProps {
  user: User
  reactivateUser: (userId: string) => Promise<void>
  confirm: (text: string, message: string) => Promise<boolean>
  loading: boolean
}

const ButtonReactive: React.FC<ButtonReactiveProps> = (
  {
    user,
    reactivateUser,
    confirm,
    loading,
  }
) => {
  const params = useHistoryParams();

  const canReactivate = checkPermission(PERMISSIONS.BROKER_USER_RESTORE) && params.scopeType === 'broker'
    || checkPermission(PERMISSIONS.COMPANY_USER_RESTORE) && params.scopeType === 'company'
  const showReactivateButton = Boolean(user.status) && user.status === USER_STATUS.INACTIVATED

  const onConfirmReactivateUser = React.useCallback(
    async (): Promise<void> => {
      const canReactivate = await confirm('Atenção', 'Quer mesmo reativar este usuário?')

      if (canReactivate) {
        await reactivateUser(params.userId)
      }
    },
    [confirm, reactivateUser, params.userId],
  )

  return (
    <>
      {
        showReactivateButton && canReactivate && (
          <Button
            outlined
            label='Reativar usuário'
            loading={ loading }
            color='default'
            onClick={() => onConfirmReactivateUser() }
          />
        )
      }

      {
        showReactivateButton && !canReactivate && (
          <Tooltip text='Você não tem permissão para reativar usuários.'>
            <Button
              outlined
              label='Reativar usuário'
              color='default'
              disabled
            />
          </Tooltip>
        )
      }
    </>
  )
}

export default ButtonReactive
