import restfulBase from '../../base';

export interface FileCategory {
  id: string
  categoryParentId: string | null
  name: string
  createdAt: string
  updatedAt: string | null
  deletedAt: string | null
  children?: FileCategory[]
}

export const categoriesProvider = restfulBase<FileCategory>('categories')

export default categoriesProvider
