import React from 'react';

import { FILES_PERMISSION_ID } from '@/providers/accessControl/endpoints/permissions';
import { capitalize } from 'lodash';
import { Checkbox, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

export interface BlockPermissionTitleProps {
  name: string,
  shouldDisabled: (id: string, type: FILES_PERMISSION_ID) => boolean,
  id: string,
  handleBlockPermission: (id: string, type: FILES_PERMISSION_ID, isChecked: boolean) => void
  addPermission: boolean,
  viewPermission: boolean,
  deletePermission: boolean,
  addIndeterminatePermission: boolean,
  viewIndeterminatePermission: boolean,
  deleteIndeterminatePermission: boolean,
  isLoading: boolean
}

const BlockPermissionTitle: React.FC<BlockPermissionTitleProps> = ({
  name,
  shouldDisabled,
  id,
  handleBlockPermission,
  addPermission,
  addIndeterminatePermission,
  viewPermission,
  viewIndeterminatePermission,
  deletePermission,
  deleteIndeterminatePermission,
  isLoading
}) => {

  const checkAll = (type: FILES_PERMISSION_ID): boolean => {
    if (type === FILES_PERMISSION_ID.CREATE) {
      return !(addPermission || addIndeterminatePermission);
    }
    if (type === FILES_PERMISSION_ID.DELETE) {
      return !(deletePermission || deleteIndeterminatePermission);
    }
    if (type === FILES_PERMISSION_ID.READ) {
      return !(viewPermission || viewIndeterminatePermission);
    }
    return false;
  }

  const isDisabledAdd = shouldDisabled(id, FILES_PERMISSION_ID.CREATE) || isLoading;
  const isDisabledView = shouldDisabled(id, FILES_PERMISSION_ID.READ) || isLoading;
  const isDisabledDelete = shouldDisabled(id, FILES_PERMISSION_ID.DELETE) || isLoading;

  const onChangeCheck = (type: FILES_PERMISSION_ID): void => {
    handleBlockPermission(id, type, checkAll(type));
  }

  return (
    <Row style={{ padding: 0 }}>
      <Grid alignItems='center'>
        <Row style={{ padding: 0, width: '35%' }}>
          <Text textAlign='left' color='default' value={ capitalize(name) } title />
        </Row>
        <Row style={{ padding: 0, width: '60%' }}>
          <Grid alignContent='justify'>
            <Row style={{ padding: 0 }}>
              <Checkbox small label='Adicionar todas'
                indeterminate={ addIndeterminatePermission }
                disabled={ isDisabledAdd }
                title={ isDisabledAdd ? 'Você não pode adicionar ou alterar esta permissão geral.' : '' }
                checked={ addPermission }
                onChange={ () => onChangeCheck(FILES_PERMISSION_ID.CREATE) }
              />
            </Row>
            <Row style={{ padding: 0 }}>
              <Checkbox small label='Visualizar todas'
                indeterminate={ viewIndeterminatePermission }
                disabled={ isDisabledView }
                title={ isDisabledView ? 'Você não pode adicionar ou alterar esta permissão geral.' : '' }
                checked={ viewPermission }
                onChange={ () => onChangeCheck(FILES_PERMISSION_ID.READ) }
              />
            </Row>
            <Row style={{ padding: 0 }}>
              <Checkbox small label='Excluir todas'
                indeterminate={ deleteIndeterminatePermission }
                disabled={ isDisabledDelete }
                title={ isDisabledDelete ? 'Você não pode adicionar ou alterar esta permissão geral.' : '' }
                checked={ deletePermission }
                onChange={ () => onChangeCheck(FILES_PERMISSION_ID.DELETE) }
              />
            </Row>
          </Grid>
        </Row>
      </Grid>
    </Row>
  )
}

export default BlockPermissionTitle;
