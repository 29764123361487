import { USER_STATUS } from '@/pages/users/list/entities/user.enums';
import restfulBase from '@/providers/accessControl/base'
import { ObjectType } from '@/shareds/types'
import { UserPayload } from '../users'

export enum Scopes {
  BROKER = 'broker',
  COMPANY = 'company',
}

export interface User {
  id?: string
  name: string
  email: string
  type: Scopes
  status?: USER_STATUS
  permissions: string[]
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  scopeIds?: string[]
}

export const userInitialState: User = {
  name: '',
  email: '',
  type: Scopes.COMPANY,
  scopeIds: [],
  permissions: []
}

export const userMessageErrorByMessageErrorRequest: ObjectType = {
  'E-mail address already registered for another user': 'Endereço de e-mail já registrado por outro usuário'
}

export const userDetailsProvider = restfulBase<User, UserPayload>('/auth/me')
