import React from 'react'
import { List } from 'vkit/lib/components'
import CustomListType from './types/customList.types'
import style from './style/customList.module.scss'

const CustomList: React.FC<CustomListType> = ({
  data,
  innerHeight,
  onPage,
  dataList,
  listRef,
  dataResolve,
  hideHeader,
  itemPushed,
  onClick,
  onHover,
  onRefresher,
  textToEmpty,
  blur,
}) => {
  return (
    <div {...(listRef && { ref: listRef })} className={`${style.list} ${blur ? style.blur : ''}`}>
      <List
        hideHeader={hideHeader}
        dataResolve={dataResolve}
        itemPushed={itemPushed}
        onClick={onClick}
        onHover={onHover}
        onRefresher={onRefresher}
        textToEmpty={textToEmpty}
        data={data}
        innerHeight={innerHeight}
        onPage={onPage}
        dataList={dataList}
      />
    </div>
  )
}

export default CustomList
