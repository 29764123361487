export enum USER_STATUS {
  PENDING = 'pending',
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  INACTIVATED = 'deleted',
}

export enum USER_LIST_FILTERS {
  SEARCH = 'search',
  EMAIL = 'email',
  STATUS = 'status',
}
