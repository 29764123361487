import { useCallback, ReactNode, useRef, useEffect } from 'react'
import { Toast } from 'vkit/lib/components'

type Color = 'danger' | 'info' | 'success' | 'warning' | 'default'
type Status = 'info' | 'success' | 'warning' | 'error' | 'waiting'

interface Options {
  [key: string]: {
    icon: string
    color?: Color
  }
}

interface UseToastStatus {
  toastComponent?: ReactNode
  toast: (title: string, description: string, status: Status, duration?: number) => void
}

const useToastStatus = (): UseToastStatus => {
  const { toast: toastComponent, open: openToast } = Toast()
  const optionsRef = useRef<Options>({
    info: {
      icon: 'info-outline',
      color: 'info',
    },
    success: {
      icon: 'checkmark-circle-2-outline',
      color: 'success',
    },
    warning: {
      icon: 'alert-triangle-outline',
      color: 'warning',
    },
    error: {
      icon: 'close-circle-outline',
      color: 'danger',
    },
    waiting: {
      icon: 'clock-outline',
      color: 'default',
    },
  })

  const toast = useCallback(
    (title: string, description: string, status: Status, duration?: number): void => {
      const options = optionsRef.current[status]
  
      const toastEvent = new CustomEvent('toast', {
        detail: {
          icon: options.icon,
          title: title || 'Atenção',
          text: description,
          type: options.color,
          align: 'right',
          titleColor: options.color,
          position: 'bottom',
          shadow: true,
          duration: duration || 10,
        }
      })
      
      window.dispatchEvent(toastEvent)
    },
    [],
  )
  
  useEffect(() => {
    const callToast = (event: any): void => openToast(event?.detail || {})
    window.addEventListener('toast', callToast)
    
    return () =>  {
      window.removeEventListener('toast', callToast)
    }
  }, [openToast])

  return {
    toastComponent,
    toast,
  }
}

export default useToastStatus
