enum TextFiledTypeEnum {
  cep = 'cep',
  cnpj = 'cnpj',
  cpf = 'cpf',
  date = 'date',
  number = 'number',
  password = 'password',
  text = 'text',
}

export default TextFiledTypeEnum
