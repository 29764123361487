import React from 'react'
import RouterType from './types/router.type'
import useComponentsByRoute from './hooks/useComponentByRoute'

const ModalPage: React.FC<RouterType> = ({ routesGroups }) => {
  const { modalPage } = useComponentsByRoute(routesGroups)

  return <>{modalPage}</>
}

export default ModalPage
