import { FILES_PERMISSION_ID } from '@/providers/accessControl/endpoints/permissions';
import React from 'react';
import { Accordion } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';
import { BlockPermissionTitle } from '..';
import { ListCategories } from '../../utils';
import { BlockPermissionTitleProps } from '../blockPermissionTitle/blockPermissionTitle';

interface BlockPermissionProps extends Pick<BlockPermissionTitleProps, 'handleBlockPermission' | 'shouldDisabled'> {
  category: ListCategories
  expanded: boolean
  isLoading: boolean
}

interface CheckCurrentBlockPermission {
  checked: boolean,
  indeterminate: boolean,
}

const BlockPermission: React.FC<BlockPermissionProps> = ({
  category,
  expanded,
  children,
  shouldDisabled,
  handleBlockPermission,
  isLoading
}) => {

  const checkCurrentBlock = (type: FILES_PERMISSION_ID): CheckCurrentBlockPermission => {
    let checked = false;
    let indeterminate = false;
    if (type === FILES_PERMISSION_ID.CREATE ) {
      checked = category.children.every((item) => item.hasAddPermission);
      indeterminate = category.children.some((item) => item.hasAddPermission) && !checked;
    }
    if (type === FILES_PERMISSION_ID.DELETE ) {
      checked = category.children.every((item) => item.hasDeletePermission);
      indeterminate = category.children.some((item) => item.hasDeletePermission) && !checked;
    }
    if (type === FILES_PERMISSION_ID.READ ) {
      checked = category.children.every((item) => item.hasViewPermission);
      indeterminate = category.children.some((item) => item.hasViewPermission) && !checked;
    }

    return {
      checked,
      indeterminate
    }
  }

  return (
    <Row style={{ padding: '0 0 12px' }}>
      <Accordion
        box
        full
        expanded={ expanded }
        title={
          <BlockPermissionTitle
            isLoading={ isLoading }
            name={ category.name }
            id={ category.id }
            shouldDisabled={ shouldDisabled }
            handleBlockPermission={ handleBlockPermission }
            addPermission={ checkCurrentBlock(FILES_PERMISSION_ID.CREATE).checked }
            addIndeterminatePermission={
              checkCurrentBlock(FILES_PERMISSION_ID.CREATE).indeterminate }
            deletePermission={ checkCurrentBlock(FILES_PERMISSION_ID.DELETE).checked }
            deleteIndeterminatePermission={
              checkCurrentBlock(FILES_PERMISSION_ID.DELETE).indeterminate }
            viewPermission={ checkCurrentBlock(FILES_PERMISSION_ID.READ).checked }
            viewIndeterminatePermission={
              checkCurrentBlock(FILES_PERMISSION_ID.READ).indeterminate }
          />
        }
      >
        { children }
      </Accordion>
    </Row>
  )
}

export default BlockPermission;
