import Search from 'components/webform/select/container/list/search'
import TabsList from 'components/webform/select/container/tabs'
import DataType from 'components/webform/select/types/data.type'
import React, { ReactNode } from 'react'
import FloatLayer from 'shared/components/floatLayer'
import style from 'theme/components/webform/select/select.module.scss'
import BaseList, { ColumnSelectOption } from './base'

type ListMultipleType = {
  getElement: ({}: HTMLDivElement) => void
  isChecked: Function
  isRequestable: boolean
  label?: string
  onClick?: Function
  onSearch: (...item: any) => void
  onSearchSelecteds: (...item: any) => void
  searchable?: boolean
  selecteds?: DataType[] | null
  setListIndex: Function
  setPage: Function
  show: boolean
  showTabSelecteds?: boolean
  useContentList?: DataType[] | null
  useContentListSelecteds?: DataType[] | null
  useListIndex: number
  maxHeight: number
  useTerm: string
  useTermSelecteds: string
  fixedList?: boolean
  customOption?: (option: DataType) => ReactNode
  isRequest?: boolean
  requestFinished?: boolean
  customMessageInitial?: string
  customMessageNotFound?: string
  columns?: ColumnSelectOption[]
}

const ListMultiple: React.FC<ListMultipleType> = ({
  getElement,
  label,
  onClick,
  onSearch,
  onSearchSelecteds,
  searchable,
  setListIndex,
  show,
  showTabSelecteds,
  useContentList,
  useContentListSelecteds,
  useListIndex,
  selecteds,
  useTerm,
  useTermSelecteds,
  fixedList,
  customOption,
  customMessageInitial,
  customMessageNotFound,
  isRequest,
  requestFinished,
  columns,
}) => (
  <FloatLayer
    fixed={fixedList}
    full
    outlined
    title={label ?? 'Selecione'}
    rounded
    show={show}
    height={window.innerHeight}
    elevation={5}
    header={
      <div>
        {!!searchable &&
          (useListIndex === 0 ? (
            <Search onSearch={onSearch} term={useTerm} />
          ) : (
            <Search onSearch={onSearchSelecteds} term={useTermSelecteds} />
          ))}
      </div>
    }
  >
    <div className={columns?.length ? style.table : style.list}>
      {showTabSelecteds && (
        <TabsList
          numSelecteds={selecteds?.length ?? 0}
          tabActive={useListIndex}
          onChange={setListIndex as () => void}
        />
      )}

      <div ref={getElement}>
        {useListIndex === 0 && (
          <BaseList
            customOption={customOption}
            columns={columns}
            data={useContentList}
            multiple
            onClick={onClick}
            selecteds={selecteds}
            isRequest={isRequest}
            requestFinished={requestFinished}
            customMessageInitial={customMessageInitial}
            customMessageNotFound={customMessageNotFound}
          />
        )}

        {useListIndex === 1 && (
          <BaseList
            columns={columns}
            customOption={customOption}
            data={useContentListSelecteds}
            multiple
            onClick={onClick}
            selecteds={selecteds}
            customMessageNotFound={customMessageNotFound}
          />
        )}
      </div>
    </div>
  </FloatLayer>
)

export default ListMultiple
