import React from 'react'
import FieldBase from './base'
import { FieldBaseType } from '../types/fieldBase.type'
import { TypeEnum } from '../enums'
import { InputEnum } from '../../builder/container/field/types/field.type'

const NumberField: React.FC<FieldBaseType> = (props) => {
  if (props.native) {
    return <FieldBase {...props} inputType={InputEnum.number} type={TypeEnum.number} />
  }

  const removeNotNumbers = (value: any): string => {
    return String(value).replace(/[^+\-\d.]/g, '')
  }

  const validateValue = (value: string): boolean => {
    if (!value) {
      return true
    }

    const regexTest = /^[+-]?(\d*?\d\.?\d*)?$/
    return regexTest.test(value)
  }

  const handleBeforeChange = (value: string): string => {
    const valid = validateValue(value)
    return valid ? value : removeNotNumbers(props.value || '')
  }

  return <FieldBase {...props} type={TypeEnum.number} handleBeforeChange={handleBeforeChange} />
}

export default NumberField
