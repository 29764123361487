import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react'

import { DataType } from 'vkit/lib/shared/components/list/types/list.type'
import { Row } from 'vkit/lib/context'
import { Button, Text } from 'vkit/lib/components'
import {
  EmailConfirmation,
  emailConfirmationProvider,
  TYPES,
  TYPES_TRANSLATE,
  userMessageErrorByMessageErrorRequest,
} from '@/providers/accessControl/endpoints/email-confirmations'
import TimerDown from '@/pages/users/Details/components/Timer/Timer'
import {
  authEmailConfirmationSendsErrors,
  authEmailConfirmationSendsService,
} from '@/providers/accessControl/endpoints/email-confirmations-sends'
import { toastError } from '@/utils/toastError'
import { ToastStatus } from '@/components'
import { format } from 'date-fns'
import TextType from 'vkit/lib/shared/components/text/types/text.type'
import { EVENTS } from '@/shareds/constants/events'

interface ButtonResendProps {
  type: TYPES
  userId: string
}

const ButtonResend: React.FC<ButtonResendProps> = ({ type, userId }) => {
  const [loading, setLoading] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const { toast } = ToastStatus()
  
  const handleRequestResend = async (type: TYPES): Promise<void> => {
    try {
      setLoading(true)
      await authEmailConfirmationSendsService.create({ userId, type })
      toast('Feito', 'Reenviamos uma mensagem para o email do usuário.', 'success')
      setDisabled(true)
    } catch (error) {
      toastError({
        error,
        messageDefault: 'Falha ao reenviar o e-mail de confirmação',
        messageErrorByMessageErrorRequest: authEmailConfirmationSendsErrors,
      })
    } finally {
      setLoading(false)
    }
  }
  
  return (
    <Button
      disabled={disabled}
      label='Reenviar'
      loading={loading}
      onClick={() => handleRequestResend(type) }
      outlined
      size='small'
      solo
    />
  )
}

const Status: React.FC<{ emailConfirmation: EmailConfirmation }> = ({ emailConfirmation }) => {
  const { type, isExpired, confirmedAt } = useMemo(
    () => emailConfirmation, [emailConfirmation]
  )
  const [statusOptions, setStatusOptions] = useState<TextType>({
    color: 'warning',
    value: 'Aguardando confirmação',
  })
  
  useEffect(() => {
    if (confirmedAt) {
      return setStatusOptions({
        color: 'success',
        value: 'Confirmado',
      })
    }
  
    if (isExpired && type !== TYPES.REGISTRATION) {
      return setStatusOptions({
        color: 'danger',
        value: 'Expirado',
      })
    }
  }, [confirmedAt, isExpired, type])
  
  return ( <Text value={statusOptions.value} /> )
}

const useUserHistory = (
  userId: string
): {
  getColumnData: DataType[],
  getData: EmailConfirmation[],
  useListRef: MutableRefObject<HTMLDivElement | null>
  isLoading: boolean
} => {
  const [getColumnData, setColumnData] = React.useState<DataType[]>([]);
  const [getData, setData] = React.useState<EmailConfirmation[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const useListRef = React.useRef(null);

  const getOperationText = (type: TYPES): string => {
    return TYPES_TRANSLATE[type];
  }
  
  const parseDateTime = (dateTime: string): string => {
    return format(new Date(dateTime), 'dd/MM/yyy\', às \' HH:mm')
  }

  const setColumns = React.useCallback((): DataType[] => {
    const columns: DataType[] = [
      {
        title: 'Operação',
        value: function renderColumn({ type }: { type: TYPES }) {
          return (
            <Row style={{ padding: 0 }}>
              <Text value={getOperationText(type) || 'Nome não informado'} title size='small' />
            </Row>
          )
        },
      }
    ];
    
    if (window.innerWidth > 768) {
      columns.push(
        ...[
          {
            title: 'Criado em',
            value: function renderColumn({ createdAt }: EmailConfirmation) {
              return (
                <Row style={{ padding: 0 }}>
                  <Text value={createdAt ? parseDateTime(createdAt) : '-'} size='small' />
                </Row>
              )
            },
          },
          {
            title: 'Confirmado em',
            value: function renderColumn({ confirmedAt }: EmailConfirmation) {
              return (
                <Row style={{ padding: 0 }}>
                  <Text value={confirmedAt ? parseDateTime(confirmedAt) : '-'} size='small' />
                </Row>
              )
            },
          },
          {
            title: 'Status',
            value: (emailConfirmation: EmailConfirmation) => (
              <>
                <Status emailConfirmation={emailConfirmation} />
                {
                  emailConfirmation.expiresAt
                  && !emailConfirmation.confirmedAt
                  && !emailConfirmation.isExpired
                  && ( <TimerDown dateEnd={new Date(emailConfirmation.expiresAt)}/> )
                }
              </>
            ),
          },
          {
            darken: true,
            title: 'Solicitado por',
            value: function renderColumn({ createdByUser }: EmailConfirmation) {
              return createdByUser?.name || '-'
            },
          },
        ],
      )
    }

    columns.push({
      align: 'right',
      width: '80px',
      value: function renderColumn({ isExpired, type, confirmedAt }: EmailConfirmation) {
        return (
          !confirmedAt && (!isExpired || type === TYPES.REGISTRATION)
            ? <ButtonResend type={type} userId={userId} />
            : ''
        )
      },
    })

    return columns;
  }, [userId]);

  const fetchData = useCallback( async (): Promise<void> => {
    setLoading(true);
    setData([]);

    if (!userId) {
      setLoading(false);
      return;
    }

    try {
      const { data } = await emailConfirmationProvider.index({
        userId,
        include: 'createdByUser',
        sort: '-createdAt',
        pageSize: 100,
      })
      setData(data);
    } catch (error) {
      setData([]);
      toastError({
        error,
        messageDefault: 'Falha ao carregar histórico',
        messageErrorByMessageErrorRequest: userMessageErrorByMessageErrorRequest,
      })
    } finally {
      setLoading(false);
    }

  }, [userId]);

  useEffect((): void => {
    const columns = setColumns();
    setColumnData(columns);
    (async () => fetchData())()
  }, [setColumns, fetchData])
  
  useEffect(() => {
    window.addEventListener(EVENTS.REFRESH_USERS, fetchData)
    
    return () =>  {
      window.removeEventListener(EVENTS.REFRESH_USERS, fetchData)
    }
  }, [fetchData])

  return {
    isLoading,
    useListRef,
    getColumnData,
    getData
  }
}

export default useUserHistory
