import { CustomList } from '@/components'
import useContentList from '@/pages/users/list/hooks/useContentList'
import { ObjectType } from '@/shareds/types'
import React from 'react'

const innerHeight = window.innerWidth > 768 ? 60 : 80
const PAGE_SIZE_DEFAULT = 3

interface ContentListProps {
  blur: boolean
  data: ObjectType[] | null
}

const ContentList: React.FC<ContentListProps> = (
  {
    data,
    blur,
  }
) => {
  const { useDataList, useListRef } = useContentList()

  return (
    <CustomList
      blur={blur}
      listRef={useListRef}
      size={(data || []).length || PAGE_SIZE_DEFAULT}
      data={data}
      innerHeight={innerHeight}
      dataList={useDataList}
    />
  )
}

export default ContentList
