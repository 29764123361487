import usersRoute from './users'
import deletedUsersRoute from './deleted-users'

const routesGroups = [
  {
    title: '',
    routes: [
      usersRoute,
      deletedUsersRoute
    ],
  },
]

export default routesGroups
