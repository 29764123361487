function getIsContainsRecipient(element: HTMLDivElement): boolean {
  if (element) {
    const { innerWidth: rootX, innerHeight: rootY } = window

    const clientContains = element.getBoundingClientRect()
    const { left, top, right: innerClientX, bottom: innerClientY } = clientContains

    const isInnerClientX = left > 0 && innerClientX <= rootX
    const isInnerClientY = top > 0 && innerClientY <= rootY

    return isInnerClientX && isInnerClientY
  }
  return false
}

export default getIsContainsRecipient
