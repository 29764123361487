import { BoxInfo, FormValidate, GridGroup, SelectValidate, TextFieldValidate, ToastStatus } from '@/components';
import { SelectOption } from '@/components/webForm/selectValidate';
import { useHistoryParams } from '@/navigation';
import Actions from '@/pages/users/createOrUpdate/components/Actions/Actions';
import useInfoForm from '@/pages/users/createOrUpdate/forms/info/hooks/useInfoForm';
import { USER_STATUS_TRANSLATED } from '@/pages/users/list/entities/user.constants';
import { User, UserPermission } from '@/providers/accessControl/endpoints/users';
import { getTokenPayload } from '@/utils/authentication';
import { FileCategoryPermissions } from '@/views/fileCategoryPermissions';
import React, { useMemo } from 'react';
import { TextField } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';

interface InfoFormProps {
  useData: User
  onChangeUpdate: Function
  onChangeStep: Function
  loading: boolean
  usePermissionOptions: SelectOption[]
  userPermissionScoped: UserPermission[]
  disabled?: boolean
}

const MAXLENGTH_DEFAULT = 120

const InfoForm: React.FC<InfoFormProps> = (
  {
    loading,
    onChangeStep,
    onChangeUpdate,
    useData,
    usePermissionOptions,
    userPermissionScoped,
    disabled,
  },
) => {
  const {
    checkEmailBroker,
    createOrUpdate,
    useLoading,
    setFileFoldersPermission,
  } = useInfoForm({
    onChangeStep,
    onChangeUpdate,
    useData,
  })
  const { userId, scopeType } = useHistoryParams()
  const { toast } = ToastStatus()
  const userIdLogged = useMemo(() => {
    const { sub } = getTokenPayload() || {}
    return sub
  },[])

  return (
    <BoxInfo
      icon='person-outline'
      title='Dados do usuário'
      loading={loading}
      header={useData.id && (
        <Actions user={useData} />
      )}
    >
      <FormValidate
        resource={useData}
        fields={{
          name: ['required'],
          email: [
            'required',
            'email',
            ...(scopeType === 'broker' ? [checkEmailBroker] : []),
          ],
          permissions: [],
        }}
        onChangeForm={onChangeUpdate}
        onError={() =>
          toast('Puxa!', 'Existem alguns campos com preenchimento irregular.', 'warning')
        }
        onSubmit={() => {
          if (!disabled) {
            createOrUpdate(useData)
          }
        }}
        body={({ onBlur, onChange, useErrors, useValues }) => (
          <>
            <GridGroup
              body={[
                {
                  default: 50,
                  component: (
                    <TextFieldValidate
                      label='Nome'
                      maxLength={MAXLENGTH_DEFAULT}
                      name='name'
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder='Informe o nome do usuário'
                      required
                      useErrors={useErrors}
                      useValues={useValues}
                      disabled={disabled}
                    />
                  ),
                },

                {
                  default: 50,
                  component: (
                    <TextFieldValidate
                      disabled={userId || disabled}
                      label={`Email ${scopeType === 'broker' ? '(@vitta ou @stone)' : ''}`}
                      loading={useLoading.email}
                      maxLength={MAXLENGTH_DEFAULT}
                      name='email'
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder='Informe o email'
                      required
                      useErrors={useErrors}
                      useValues={useValues}
                    />
                  ),
                },

                {
                  default: 50,
                  component: (
                    <TextField
                      label='Status'
                      disabled
                      value={
                        useData.status && !loading
                          ? USER_STATUS_TRANSLATED[useData.status]
                          : ''
                      }
                    />
                  ),
                },

                {
                  default: 50,
                  component: (
                    <SelectValidate
                      multiple
                      label='Permissões'
                      name='permissions'
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder='Selecione as permissões do usuário'
                      useErrors={useErrors}
                      useValues={useValues}
                      data={usePermissionOptions}
                      disabled={
                        !usePermissionOptions.length
                        || useData.id === userIdLogged
                        || disabled
                      }
                    />
                  ),
                },
              ]}
            />
            <Row style={{ padding: '24px 0 0 0' }}>
              { !loading && (
                <FileCategoryPermissions
                  isLoading={ useLoading.save }
                  updateFilesDirPermissions={ setFileFoldersPermission }
                  initialPermissions={ userPermissionScoped }
                  disabled={disabled}
                />
              ) }
            </Row>
          </>
        )}
        buttons={{
          submit: {
            disabled,
            tooltip: disabled ? 'Este usuário está inativo, portanto a funcionalidade de alteração não é permitida.' : null,
            loading: loading || useLoading.save,
            text: useData.id ? 'Alterar usuário' : 'Registrar novo usuário',
          },
        }}
      />
    </BoxInfo>
  )
}

export default InfoForm
