import { useState, useCallback, useRef } from 'react'

const useDialogConfirm = (): {
  useTitle?: string
  useText?: string
  useOpen?: boolean
  useFnCallback?: (response: boolean) => void
  useConfirm: (title: string, text: string) => Promise<boolean>
} => {
  const [useTitle, setTitle] = useState<string>()
  const [useText, setText] = useState<string>()
  const [useOpen, setOpen] = useState<boolean>()

  const useFnCallback = useRef<(response: boolean) => void>()

  const useConfirm = useCallback(async (title: string, text: string): Promise<boolean> => {
    const setCallback: Promise<boolean> = new Promise((resolve) => {
      useFnCallback.current = (response: boolean): void => {
        setOpen(false)
        resolve(response)
      }
    })

    setTitle(title)
    setText(text)
    setOpen(true)

    return setCallback
  }, [])

  return {
    useTitle,
    useText,
    useOpen,
    useFnCallback: useFnCallback.current,
    useConfirm,
  }
}

export default useDialogConfirm
