import { useHistoryParams } from '@/navigation';
import { USER_STATUS } from '@/pages/users/list/entities/user.enums';
import { User } from '@/providers/accessControl/endpoints/user';
import { checkPermission, PERMISSIONS } from '@/utils/checkPermission';
import React, { useMemo } from 'react';
import { Button } from 'vkit/lib/components';
import { Tooltip } from 'vkit/lib/context';

interface ButtonReset2FAProps {
  user: User
  reset2FA: (userId: string) => Promise<void>
  confirm: (text: string, message: string) => Promise<boolean>
  loading: boolean
}

const ButtonReset2FA: React.FC<ButtonReset2FAProps> = (
  {
    user,
    reset2FA,
    confirm,
    loading
  }
) => {
  const params = useHistoryParams();
  const tooltipText = useMemo(() => {
    if (user.status === USER_STATUS.PENDING) {
      return 'Você não pode redefinir autenticação em dois fatores, pois usuário não foi confirmado.';
    }

    return 'Você não tem permissão para redefinir autenticação em dois fatores.';
  }, [user.status])
  
  const canReset2FA = useMemo(() => {
    if (user.status === USER_STATUS.PENDING) {
      return false
    }

    if (checkPermission(PERMISSIONS.COMPANY_TOTP_RESET) && params.scopeType === 'company') {
      return true;
    }

    return checkPermission(PERMISSIONS.BROKER_TOTP_RESET) && params.scopeType === 'broker'
  }, [params.scopeType, user.status])
  const showButtonReset2FA = Boolean(user.status) && user.status !== USER_STATUS.INACTIVATED

  const onConfirmReset2FA = React.useCallback(
    async (): Promise<void> => {
      const canReset = await confirm('Atenção', 'Quer mesmo redefinir a autenticação em dois fatores deste usuário?')
      
      if (canReset) {
        await reset2FA(params.userId)
      }
    },
    [reset2FA, confirm, params],
  )

  return (
    <>
      {
        showButtonReset2FA && canReset2FA && (
          <Button
            solo
            outlined
            label='Redefinir autenticação em dois fatores'
            disabled={ loading }
            onClick={() => onConfirmReset2FA() }
          />
        )
      }

      {
        showButtonReset2FA && !canReset2FA && (
          <Tooltip text={ tooltipText }>
            <Button
              solo
              outlined
              label='Redefinir autenticação em dois fatores'
              disabled
            />
          </Tooltip>
        )
      }
    </>
  )
}

export default ButtonReset2FA
