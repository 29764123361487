import React from 'react'
import FieldBase from './base'
import { FieldBaseType } from '../types/fieldBase.type'
import { TypeEnum } from '../enums'
import { InputEnum } from '../../builder/container/field/types/field.type'
import style from '../../../../theme/components/webform/textField.module.scss'
import Icon from '../../../../shared/components/icon'

const IconCalendar: React.FC = () => (
  <div className={`${style.iconCustom} ${style.cursorPointer} ${style.iconButton}`}>
    <Icon name="calendar-outline" size={20} />
  </div>
)

const DateField: React.FC<FieldBaseType> = (props) => (
  <FieldBase
    {...props}
    inputType={InputEnum.date}
    type={TypeEnum.date}
    contentRight={<IconCalendar />}
  />
)

export default DateField
