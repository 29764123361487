import restfulService from '@/utils/restful'

type Param<T> = Partial<T> & {
  pageSize?: number
  page?: number
}

export interface DataListResponse<T> {
  page: number
  pageSize: number
  data: T[]
  total: number
}

export interface DataResponse<T> {
  data: T
}

export interface AccessControlError {
  response: {
    data: {
      message: string
    }
  }
}

export const restfulBase = <T, P = undefined>(
  service: string,
  isMocked?: boolean,
): {
  create: (params: P, url?: string) => Promise<T>
  find: (id: string, params?: Param<T>) => Promise<T>
  list: (params?: Param<T>, url?: string) => Promise<T[]>
  listAll: (params: Param<T>, url?: string) => Promise<T[]>
  remove: (id: string, url?: string) => Promise<any>
  update: (id: string, params: Partial<P>, uri?: string) => Promise<any>
  index: (params: Param<T>, uri?: string) => Promise<DataListResponse<T>>
} => {
  const {
    REACT_APP_API_ACCESS_CONTROL_URL = '',
    REACT_APP_MOCK_API = '',
  } = process.env

  const activeBasePath = isMocked ? REACT_APP_MOCK_API : REACT_APP_API_ACCESS_CONTROL_URL;

  const api = restfulService({
    service,
    uri: activeBasePath,
  })
  
  api.instance.interceptors.request.use((config) => ({
    ...config,
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }))

  return {
    create: async (params: P, url?: string): Promise<T> => {
      const { data } = await api.post(params, url) as DataResponse<T>
      return data
    },

    find: async (id: string, params?: Param<T>): Promise<T> => {
      const { data } = await api.get(params, `/${id}`) as DataResponse<T>
      return data
    },

    list: async (params?: Param<T>, url?: string): Promise<T[]> => {
      const { data } = await api.get(params, url) as DataListResponse<T>
      return data
    },

    listAll: async (params: Param<T>, uri?: string): Promise<T[]> => {
      const restful = restfulBase<T, P>(service)
      const { total, data } = await restful.index(params, uri) || {}
      const pages = Array.from({ length: total - 1 }, (_, num) => num + 2)

      for (const page of pages) {
        const items = await restful.list({ ...params, page }, uri) || {}
        data.push(...items)
      }

      return data
    },

    index: async (params: Param<T>, uri?: string): Promise<DataListResponse<T>> => {
      const response = await api.get(params, uri) as DataListResponse<T>
      return Array.isArray(response?.data) ? response : response?.data
    },

    remove: async (id: string, url?: string): Promise<void> =>
      api.remove(id, url) as Promise<void>,

    update: async (id: string, params: Partial<P>, uri?: string): Promise<T> =>
      api.update(params, id, uri) as Promise<T>,
  }
}

export default restfulBase
