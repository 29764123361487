import { useState, useCallback } from 'react'

const useFloatLayerBounding = (): {
  getElementHeight: ({}: HTMLDivElement) => void
  useHeight: number
  useWidth: number
  useTop: number | 'auto'
} => {
  const [useHeight, setHeight] = useState(0)
  const [useWidth, setWidth] = useState(0)
  const [useTop, setTop] = useState<number | 'auto'>('auto')

  const getDetailsHeight = (divElement: HTMLDivElement): number => {
    const headerIndex = 0
    const titleIndex = 1
    const footerIndex = 3

    const { childNodes } = divElement

    return [headerIndex, titleIndex, footerIndex].reduce((acc, detailIndex) => {
      const { height } = (childNodes?.[detailIndex] as HTMLDivElement).getBoundingClientRect()
      return acc + height
    }, 0)
  }

  const defineHeight = useCallback(
    (
      divElement: HTMLDivElement,
    ): {
      origin: number
      list: number
      details: number
    } => {
      const bottomWindow = 50
      const detailsHeight = getDetailsHeight(divElement)
      const { height: floatLayerHeight } = divElement.getBoundingClientRect()
      const { top: floatLayerParentTop = 0 } =
        divElement.parentElement?.getBoundingClientRect() || {}
      const floatLayerTop = Math.max(floatLayerParentTop, 0)
      const contentHeight =
        floatLayerHeight + floatLayerTop > window.innerHeight
          ? window.innerHeight - floatLayerTop
          : floatLayerHeight

      return {
        origin: floatLayerHeight,
        list: Math.max(contentHeight - bottomWindow, 200),
        details: detailsHeight,
      }
    },
    [],
  )

  const getElementHeight = useCallback(
    (divElement: HTMLDivElement): void => {
      if (!divElement) {
        setHeight(0)
        return
      }

      const {
        origin: originHeight,
        list: contentHeight,
        details: detailsHeight,
      } = defineHeight(divElement)
      const paddingBottom = 32
      const { width, top = 0 } =
        divElement.parentElement?.parentElement?.getBoundingClientRect() || {}

      setWidth(width || 0)
      if (top + contentHeight >= window.innerHeight) {
        setHeight(contentHeight - detailsHeight)
        const minHeight = Math.min(originHeight, contentHeight)
        setTop(window.innerHeight - minHeight - paddingBottom)
      } else {
        setHeight(window.innerHeight - detailsHeight - top - paddingBottom)
        setTop('auto')
      }
    },
    [defineHeight],
  )

  return {
    getElementHeight,
    useHeight,
    useWidth,
    useTop,
  }
}

export default useFloatLayerBounding
