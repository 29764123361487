import restfulService from '@/utils/restful'

type Param<T> = Partial<T> & {
  pageSize?: number
  page?: number
  withTree?: boolean
  id?: string | string []
}

export interface DataListResponse<T> {
  page: number
  pageSize: number
  data: T[]
  results: T[]
  total: number
}

export const restfulBase = <T>(
  service: string,
  isMocked?: boolean,
): {
  index: (params: Param<T>, uri?: string) => Promise<DataListResponse<T>>
} => {
  const {
    REACT_APP_API_NEGOTIATIONS_URL = '',
    REACT_APP_MOCK_API = '',
  } = process.env

  const activeBasePath = isMocked ? REACT_APP_MOCK_API : REACT_APP_API_NEGOTIATIONS_URL;

  const api = restfulService({
    service,
    uri: activeBasePath,
  })
  
  api.instance.interceptors.request.use((config) => ({
    ...config,
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }))

  return {
    index: async (params: Param<T>, uri?: string): Promise<DataListResponse<T>> => {
      const response = await api.get(params, uri) as DataListResponse<T>
      return Array.isArray(response?.data) ? response : response?.data
    },
  }
}

export default restfulBase
