import restfulBase from '@/providers/accessControl/base'
import { TYPES } from '@/providers/accessControl/endpoints/email-confirmations'

export interface AuthEmailConfirmationSends {
  type: TYPES
  userId: string
}

export const authEmailConfirmationSendsInitState = {
  type: '',
  userId: '',
}

export const authEmailConfirmationSendsErrors = {
  'No pending email confirmations': 'Confirmação de email não encontrada',
  'Latest email confirmation already confirmed': 'Confirmação de email já confirmada',
}

export const authEmailConfirmationSendsService =
  restfulBase<AuthEmailConfirmationSends, AuthEmailConfirmationSends>('auth/email-confirmations/sends')
