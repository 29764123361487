import { ObjectType } from '@/shareds/types'

const removeInvalids = <T = ObjectType>(filters: T): T =>
  Object.entries(filters).reduce((acc, [key, value]) => {
    if (!value) {
      return acc
    }
    
    return {
      ...acc,
      [key]: value
    }
  }, {}) as T

export default removeInvalids
