import { FieldClassesType, FieldType } from '../../../types'
import { ReactNode } from 'react'
import NumberFieldType from '../../../../textField/types/numberField.type'
import { TextAreaType } from '../../../../textField/types/textArea.type'

export enum TagNameEnum {
  input = 'input',
  textarea = 'textarea',
  date = 'date',
}

export enum InputEnum {
  date = 'date',
  number = 'number',
  password = 'password',
  text = 'text',
}

export type wrapField = {
  className?: string
  children: ReactNode
  label?: string
  required?: boolean
  textHelper?: string
  style?: any
}

export type BaseFieldType = FieldType &
  FieldClassesType &
  NumberFieldType &
  TextAreaType & {
    inputType?: InputEnum
    tagName?: TagNameEnum
    style?: { [key: string]: string }
    setValueState?: Function
    contentRight?: ReactNode
    iconDir?: 'left' | 'right'
    onClear?: Function
    autofocus?: boolean
  }

export default BaseFieldType
