import { FILES_PERMISSION_ID } from '@/providers/accessControl/endpoints/permissions';
import { User } from '@/providers/accessControl/endpoints/users';
import { ListCategories } from '../utils';

export interface UseDisabledManageProps {
  userId?: string
  currentUser?: User,
  currentData: ListCategories[]
  disabled?: boolean
}

export interface UseDisabledManage {
  shouldDisableAll: () => boolean,
  shouldDisableCurrentItem: (id: string, type: FILES_PERMISSION_ID) => boolean
  shouldDisableParent: (id: string, type: FILES_PERMISSION_ID) => boolean
}

const useDisabledManage = ({
  userId,
  currentUser,
  currentData,
  disabled,
}: UseDisabledManageProps): UseDisabledManage => {
  const shouldDisableAll = (): boolean => {
    return userId === currentUser?.id
      || !currentUser?.userPermissions.length
      || Boolean(disabled);
  }

  const shouldDisableCurrentItem = (id: string, type: FILES_PERMISSION_ID): boolean => {
    if (shouldDisableAll()){
      return true;
    }

    return !currentUser?.userPermissions.some(
      (permission) => permission.scope === id && permission.permissionId === type)
  }

  const shouldDisableParent = (id: string, type: FILES_PERMISSION_ID): boolean => {
    let disableParent = true;

    if (shouldDisableAll()) {
      return true;
    }

    const findBlock = currentData.find((item) => item.id === id);

    if (findBlock) {
      for(const child of findBlock.children) {
        if (!shouldDisableCurrentItem(child.id, type)) {
          disableParent = false;
          break;
        }
      }
    }
    return disableParent;
  };

  return {
    shouldDisableAll,
    shouldDisableCurrentItem,
    shouldDisableParent
  }
};

export default useDisabledManage;
