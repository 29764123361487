const messagesError = {
  cep: 'CEP inválido.',
  cnpj: 'CNPJ inválido.',
  cpf: 'CPF inválido.',
  date: 'Data inválida.',
  number: 'Número inválido.',
  required: 'Este campo é obrigatório.',
}

export default messagesError
