import { User } from '@/providers/accessControl/endpoints/users'
import { getFormattedDate } from '@/utils/format'
import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { Text } from 'vkit/lib/components'
import { Grid, Row } from 'vkit/lib/context';
import { DataType } from 'vkit/lib/shared/components/list/types/list.type'
import { ActionsList, ChipStatus } from '../components'

const useContentList = (): {
  useDataList: DataType[]
  useListRef: MutableRefObject<HTMLDivElement | null>
} => {
  const [useDataList, setDataList] = React.useState<DataType[]>([])
  const useListRef = useRef(null)

  const getColumns = useCallback((): DataType[] => {
    const columns: DataType[] = [
      {
        title: 'NOME',
        value: function renderColumn({ name }: { name: string }) {
          return (
            <Row style={{ padding: 0 }}>
              <Text value={name || 'Nome não informado'} title size='small' />
            </Row>
          )
        },
      },
    ]

    if (window.innerWidth > 768) {
      columns.push(
        ...[
          {
            title: 'EMAIL',
            value: function renderColumn({ email }: User) {
              return (
                <Row style={{ padding: 0 }}>
                  <Text value={email || 'Email não informado'} size='small' />
                </Row>
              )
            },
          },
          {
            title: 'STATUS',
            value: function renderColumn({ status }: User) {
              return (
                <Grid>
                  { status ? <ChipStatus status={status} /> : null }
                </Grid>
              )
            },
          },
          {
            title: 'CRIADO EM',
            value: function renderColumn({ createdAt }: User) {
              return (
                <Row style={{ padding: 0 }}>
                  <Text
                    value={getFormattedDate(createdAt) || 'Data não informada'}
                    size='small'
                  />
                </Row>
              )
            },
          },
        ],
      )
    }

    columns.push({
      align: 'right',
      width: '145px',
      value: (user: User) => (<ActionsList user={user} />),
    })

    return columns
  }, [])

  useEffect(() => {
    if (useDataList.length) {
      return
    }

    const columns = getColumns()
    setDataList(columns)
  }, [getColumns, useDataList.length])

  return {
    useDataList,
    useListRef,
  }
}

export default useContentList
