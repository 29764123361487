import { useEffect, useRef } from 'react'
import { requestData, deepCopy } from '../actions'

interface useListInterface {
  autoRequest?: boolean
  onRequestFinish?: Function
  setContentList: Function
  value?: string | string[]
  options: {
    requestPageParam?: string
    requestHeaders?: { [key: string]: string }
    requestParams?: { [key: string]: string | number }
    requestResponseRootPath?: string
    requestResponseText?: string | ((item: any) => string)
    requestResponseValue?: string
    requestRouter?: string
    requestSearchParam?: string
    requestUri?: string
  }
}

const useAutoRequest = ({
  autoRequest,
  onRequestFinish,
  options,
  setContentList,
  value,
}: useListInterface): void => {
  const started = useRef<boolean>(false)

  useEffect((): void => {
    const {
      requestHeaders,
      requestPageParam,
      requestParams,
      requestResponseRootPath,
      requestResponseText,
      requestResponseValue,
      requestRouter,
      requestUri,
    } = options

    const loadPage = async (): Promise<void> => {
      try {
        started.current = true

        setContentList([])

        const params = deepCopy(requestParams) || {}

        if (requestPageParam) {
          params[requestPageParam] = 1
        }

        let content = await requestData({
          headers: new Headers(requestHeaders),
          params,
          responseText: requestResponseText ?? '',
          responseValue: requestResponseValue ?? '',
          rootPath: requestResponseRootPath,
          router: requestRouter ?? '/',
          uri: requestUri,
        })

        let listContent = content?.length ? content : null

        if (value && !Array.isArray(value)) {
          const found = listContent?.some((item) => item.value === value)

          if (!found && requestResponseValue) {
            content = await requestData({
              headers: new Headers(requestHeaders),
              params: {
                ...params,
                [requestResponseValue]: value,
              },
              responseText: requestResponseText ?? '',
              responseValue: requestResponseValue ?? '',
              rootPath: requestResponseRootPath,
              router: requestRouter ?? '/',
              uri: requestUri,
            })

            if (listContent?.length && content?.length) {
              listContent.unshift(...content)
            } else {
              listContent = content?.length ? content : listContent
            }
          }
        }

        setContentList(listContent)
        onRequestFinish?.(listContent, params)
      } catch (error) {
        setContentList(null)
      }
    }

    if (
      requestResponseText &&
      requestResponseValue &&
      requestRouter &&
      !started.current &&
      autoRequest
    ) {
      loadPage()
    }
  }, [autoRequest, options, setContentList, onRequestFinish, value])
}

export default useAutoRequest
