import { FILES_PERMISSION_ID } from '@/providers/accessControl/endpoints/permissions';
import { UserPermission } from '@/providers/accessControl/endpoints/users';
import { FileCategory } from '@/providers/files/endpoints/categories';

export interface CategoryPermission {
  hasAddPermission: boolean;
  hasViewPermission: boolean;
  hasDeletePermission: boolean;
}
export interface ListCategories extends CategoryPermission {
  id: string;
  name: string;
  permissionId?: string;
  description: string | null;
  children: ListCategories[];
}

const getCategoryPermission = (
  id: string,
  initialPermissions: UserPermission[]
): CategoryPermission => {
  const hasAddPermission = initialPermissions.some((permission) =>
    permission.scope === id && permission.permissionId === FILES_PERMISSION_ID.CREATE);

  const hasViewPermission = initialPermissions.some((permission) =>
    permission.scope === id && permission.permissionId === FILES_PERMISSION_ID.READ);

  const hasDeletePermission = initialPermissions.some((permission) =>
    permission.scope === id && permission.permissionId === FILES_PERMISSION_ID.DELETE);

  return {
    hasAddPermission,
    hasViewPermission,
    hasDeletePermission,
  }
}

export const normalizeCategories = (
  categories: FileCategory[],
  initialPermissions: UserPermission[]): ListCategories[] => {

  const normalizeRecursively = (
    category: FileCategory,
    parent: string | null = null): ListCategories => {

    const { id, name, children } = category;
    const description = parent;

    const {
      hasAddPermission,
      hasDeletePermission,
      hasViewPermission
    } = getCategoryPermission(id, initialPermissions);

    const normalizedCategory: ListCategories = {
      id,
      name,
      description,
      hasAddPermission,
      hasViewPermission,
      hasDeletePermission,
      permissionId: '',
      children: []
    };

    if (children?.length) {
      normalizedCategory.children = children.map(
        (child) => normalizeRecursively(child, name))
    }

    return normalizedCategory;
  }

  return categories.map(
    (category) => normalizeRecursively(category)) || [];
};
