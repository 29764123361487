interface History {
  onClick?: () => {}
  name: string
}

interface Breadcrumb {
  name: string
  href: string
}

export const getHistoriesBreadcrumb = (breadcrumb: Breadcrumb[] = []): History[] =>
  breadcrumb?.map(({ name, href }) => {
    const historyBreadcrumb: History = {
      name
    }
    
    if (href) {
      historyBreadcrumb.onClick = () => window.top ? window.top.location.href = href : ''
    }
    
    return historyBreadcrumb
  })
