import { ToastStatus } from '@/components'
import { useHistoryNavigator, useHistoryParams } from '@/navigation'
import { User, UserPayload, UserPermissionPayload, userMessageErrorByMessageErrorRequest, usersProvider } from '@/providers/accessControl/endpoints/users'
import { ObjectType } from '@/shareds/types'
import { toastError } from '@/utils/toastError'
import { useState } from 'react'

export interface IUseInfoForm {
  createOrUpdate: Function
  useLoading: ObjectType
  checkEmailBroker: (email: string) => string | null
  checkEmailExists: (email: string) => Promise<string | null>
  setFileFoldersPermission: (userFileDirPermissions: UserPermissionPayload[]) => void
}

export type UseInfoFormType = {
  onChangeStep: Function
  onChangeUpdate: Function
  useData: User
}

const UseInfoForm = (
  {
    onChangeUpdate,
  }: UseInfoFormType
): IUseInfoForm => {

  const [ useLoading, setLoading ] = useState<ObjectType>({})
  const [ fileFoldersPermission, setFileFoldersPermission ] = useState<UserPermissionPayload[]>([]);

  const {
    scopeId,
    scopeType,
  } = useHistoryParams()

  const navigate = useHistoryNavigator()

  const { toast } = ToastStatus()

  const getInfoDTO = (userRecord: User): UserPayload => {

    const refPermissions = userRecord.permissions.map((permission) => ({
      permissionId: permission,
    }))

    return {
      email: userRecord.email,
      name: userRecord.name,
      userPermissions: [
        ...refPermissions,
        ...fileFoldersPermission
      ],
      type: scopeType,
      scopeIds: [scopeId]
    }
  }

  const checkEmailBroker = (email: string): string | null => {
    if (!email) {
      return null
    }

    if (!(/^[a-zA-Z0-9.+=?_{|}-]+@(vitta|stone)+(\.me|\.com\.br)$/).test(email)) {
      return 'Email inválido'
    }

    return null
  }

  const checkEmailExists = async (email: string): Promise<string | null> => {
    try {
      if (!email) {
        return null
      }

      onLoading('email', true)
      const users = (await usersProvider.list({ email })) || []
      if (users.length) {
        return 'Email já existe'
      }

      return null
    } finally {
      onLoading('email', false)
    }
  }

  const onLoading = (key: string, value: boolean): void => {
    setLoading((values) => ({ ...values, [key]: value }))
  }

  const createOrUpdate = async (data: User): Promise<void> => {
    try {
      onLoading('save', true)
      const userDTO = getInfoDTO(data)
      if (data.id) {
        await usersProvider.update(data.id, userDTO)
        toast('Feito', 'Dados de usuário alterados.', 'success')
      } else {
        await usersProvider.create(userDTO)
        toast('Feito', `Enviamos uma mensagem de confirmação para o email: <b>${data.email}</b>`, 'success')
        navigate.goBack()
      }
      onChangeUpdate({ user: userDTO })
    } catch (error) {
      toastError({
        error,
        messageDefault: 'Falha ao salvar usuário',
        messageErrorByMessageErrorRequest: userMessageErrorByMessageErrorRequest,
      })
    } finally {
      onLoading('save', false)
    }
  }

  return {
    checkEmailBroker,
    checkEmailExists,
    createOrUpdate,
    useLoading,
    setFileFoldersPermission
  }
}

export default UseInfoForm
