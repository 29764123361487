import restfulService from '@/utils/restful'

type Param<T> = Partial<T> & {}

export interface Response<T> {
  data: T[]
}

export const restfulBase = <T>(
  service: string,
): {
  index: (params?: Param<T>, uri?: string) => Promise<Response<T>>
} => {
  const {
    REACT_APP_API_FILES_URL = '',
  } = process.env

  const api = restfulService({
    service,
    uri: REACT_APP_API_FILES_URL,
  })
  
  api.instance.interceptors.request.use((config) => ({
    ...config,
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }))

  return {
    index: async (params?: Param<T>, uri?: string): Promise<Response<T>>  => {
      return await api.get(params, uri) as Promise<Response<T>>
    },
  }
}

export default restfulBase
